import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppBody from "./appBody";
import "./stylesheets/index.scss";
import AppWrappers from "./appWrappers";
import { Toaster } from "sonner";

/**
 * The main application component
 */
export default function App() {
  return (
    <AppWrappers>
      <Toaster
        position="top-center"
        richColors
        className="flex justify-center"
        toastOptions={{
          className:
            "flex items-center justify-center w-auto whitespace-nowrap ",
        }}
      />

      <AppBody />
      <ReactQueryDevtools initialIsOpen={false} />
    </AppWrappers>
  );
}

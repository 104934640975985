import { appService } from "/app/src/services";
import GenericSettings from "./generic";
import Hue from "./hue";
import { App as AppType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { useQuery } from "@tanstack/react-query";
import OrderBuilderStatuses from "./orderBuilder";
import Box from "/app/src/components/generic/components/box";
import DataPull from "./dataPull";

export default function App() {
  const params = useNumberId();

  const { data: app } = useQuery({
    queryKey: ["app", params.appId],
    queryFn: () => {
      return appService.getSingle(params.appId);
    },
    enabled: Boolean(params.appId),
    initialData: { app: {} },
    select: (data: { app: AppType }) => {
      return data.app;
    },
  });

  return (
    <div className="appSettings">
      <Box>
        {app.name === "Philips Hue" ? (
          <Hue app={app} />
        ) : app.name === "Order Builder" ? (
          <OrderBuilderStatuses />
        ) : app.name === "Data Pull" ? (
          <DataPull app={app} />
        ) : (
          <GenericSettings app={app} />
        )}
      </Box>
    </div>
  );
}

import { Link } from "react-router-dom";
import { App } from "/app/src/models/app";
import { useTranslation } from "react-i18next";
import { Col } from "antd";

/**
 * Component to display a single app box in the app list
 */
export default function AppBox({
  app,
  availableApp,
}: {
  app: App;
  availableApp: boolean;
}) {
  const appSelector = `${app.name.replace(" ", "_").toLowerCase()}`;
  const formattedAppSelector = `${appSelector}_app`;
  const { t } = useTranslation();

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
    >
      <div
        className={availableApp ? "appBox" : "appBox disabled_app"}
        id={formattedAppSelector}
      >
        <div className="icon" />
        <div className="info">
          {availableApp ? (
            <h3>
              <Link to={`/apps/${app.id}`}>
                {t(`translation:${appSelector}`, appSelector)}
              </Link>
            </h3>
          ) : (
            <h3>{t(`translation:${appSelector}`, appSelector)}</h3>
          )}
          {app.description}
        </div>
      </div>
    </Col>
  );
}

/**
 * A functional component that renders the Spreadsheets application UI.
 * It uses translation hooks to display localized text content.
 *
 * @returns {JSX.Element} The rendered component for the Spreadsheets application.
 */
export function SpreadsheetsApp({ app }: { app: App }) {
  const { t } = useTranslation();

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 8 }}
      xl={{ span: 6 }}
    >
      <div className="appBox" id="spreadsheets_app">
        <div className="icon" />
        <div className="info">
          <h3>
            <Link to={`/apps/${app.id}`}>{t("translation:spreadsheets")}</Link>
          </h3>
          {t("translation:spreadsheet_description")}
        </div>
      </div>
    </Col>
  );
}

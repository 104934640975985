import { useCallback } from "react";
import { buildParams } from "/app/src/helpers/params";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useDisclosure } from "@heroui/react";

/**
 * Button to export an integration template
 */
export default function Export({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  /**
   * Function to export the integration
   * Downloads the integration template as a json file
   */
  const HandleExportClick = useCallback(() => {
    integrationService
      .exportSingle(integration.id, buildParams({ export: "json" }))
      .then((response) => {
        const data = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(response),
        )}`;
        const link = document.createElement("a");
        link.href = data;
        link.download = `${integration.name}-template.itempath.json`;
        link.click();
      });
  }, [integration.id, integration.name]);

  const onClick = useCallback(() => {
    HandleExportClick();
    onOpenChange();
  }, [HandleExportClick, onOpenChange]);

  return (
    <div>
      <HeroButton
        size="sm"
        variant="bordered"
        color="primary"
        onClick={onOpen}
        fullWidth
        className="hover:border-primary-default hover:text-default"
      >
        {t("translation:download_template")}
      </HeroButton>
      <HeroModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          modalHeader: t("translation:confirm_integration_file_download"),
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
              >
                {t("translation:cancel")}
              </HeroButton>
              <HeroButton size="sm" color="primary" onClick={onClick}>
                {t("translation:download")}
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </div>
  );
}

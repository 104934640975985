import { memo, useCallback } from "react";
import { ConfirmationMappingTypes } from "./mappingTypes";
import { ConfirmationType, Integration, Mapping } from "/app/src/models";
import { Col, Row } from "antd";
import HeroButton from "/app/src/components/HeroUi/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mappingService } from "/app/src/services";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { handlePromiseError } from "/app/src/helpers/api";

interface FormValues {
  key: string;
  value: string;
  type: `confirmation-${Lowercase<ConfirmationType>}`;
}

/**
 * Component for editing a confirmation mapping. If the mapping is a grouping type, it will also display the child mappings
 */
const EditConfirmationMapping = memo(function EditConfirmationMapping({
  mapping,
}: {
  mapping: Mapping;
}) {
  const queryClient = useQueryClient();
  const { integration } = queryClient.getQueryData<{
    integration: Integration;
  }>(["integration", mapping.integrationId]);
  const { mutateAsync: updateMapping } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
  });
  /**
   * Calls the updateMapping function with the values from the form
   */
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await updateMapping({
        value: values.value,
        key: values.key,
        id: mapping.id,
      });
      return;
    },
    [mapping.id, updateMapping],
  );

  const { mutateAsync: removeMapping } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["confirmationMappings", integration.id],
        (oldData: { mappings: Mapping[] }) => {
          const idsToRemove = new Set();

          // Recursive function to find all descendants by parentMappingId
          const findDescendants = (mappings, parentId) => {
            mappings.forEach((mapping) => {
              if (mapping.parentMappingId === parentId) {
                idsToRemove.add(mapping.id);
                findDescendants(mappings, mapping.id);
              }
            });
          };

          idsToRemove.add(response.mappingId);
          findDescendants(oldData.mappings, response.mappingId);

          return {
            mappings: oldData.mappings.filter(
              (mapping) => !idsToRemove.has(mapping.id),
            ),
          };
        },
      );
    },
  });

  /**
   * Calls the deleteMapping function with the mapping
   */
  const handleDelete = useCallback(() => {
    removeMapping(mapping);
  }, [mapping, removeMapping]);

  return (
    <Row justify="start" gutter={16}>
      <Col span={21}>
        <ConfirmationMappingTypes mapping={mapping} onSubmit={handleSubmit} />
      </Col>
      <Col span={3}>
        <HeroButton
          onClick={handleDelete}
          color="default"
          size="md"
          variant="bordered"
          isIconOnly
          className="hover:border-red-500 bg-white"
        >
          <IconBuilder size={18} color="#e00000" icon="Delete" />
        </HeroButton>
      </Col>
    </Row>
  );
});

export default EditConfirmationMapping;

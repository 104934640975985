import { Integration } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";
import ConnectionDetails from "./connectionDetails";
import HeroDivider from "/app/src/components/HeroUi/Divider";
import { isSQLType } from "/app/src/helpers";
import Filters from "/app/src/components/connection/sql/filters";

/**
 *
 * @param param0 integration
 * @returns Variance connection Settings
 */
export default function Connection({
  integration,
}: {
  integration: Integration;
}) {
  return (
    <Box>
      <ConnectionDetails integration={integration} />
      <HeroDivider className="mb-3" />
      {isSQLType(integration.connectionType) && (
        <Filters integration={integration} />
      )}
    </Box>
  );
}

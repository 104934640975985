import { Formik } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import {
  Connection as ConnectionType,
  Setting as SettingType,
} from "/app/src/models";

import { useCallback, useMemo } from "react";
import useSetting from "../setting";

function formatForm(
  value: string | undefined,
  connectionId: number | undefined,
) {
  return {
    name: "DatabaseQualifier",
    connectionId,
    type: "databaseQualifier",
    value,
  };
}
/**
 * `DatabaseQualifier` is a React functional component that renders a form for updating
 * the database qualifier setting of a given connection. It utilizes Formik for form
 * management and Ant Design components for layout and styling.
 *
 * @param {Object} props - The properties object.
 * @param {ConnectionType} props.connection - The connection object containing the connection ID.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <DatabaseQualifier connection={connection} />
 *
 * @component
 * @example
 * // Example usage:
 * const connection = { id: '123', name: 'My Connection' };
 * <DatabaseQualifier connection={connection} />
 *
 * @remarks
 * This component uses the `useTranslation` hook for internationalization and the `useSetting`
 * hook for managing the setting state. The form submission is handled by the `handleSubmit`
 * function, which formats the form values and calls the `createUpdateSetting` function.
 *
 * @requires useTranslation
 * @requires useSetting
 * @requires Formik
 * @requires Ant Design components (Form, Row, Col, Input, SubmitButton)
 */
export default function DatabaseQualifier({
  connection,
}: {
  connection: ConnectionType;
}) {
  const { t } = useTranslation();

  const { settingValue, createUpdateSetting } = useSetting({
    connectionId: connection.id,
    type: "databaseQualifier",
  });

  const handleSubmit = useCallback(
    async (values: SettingType) => {
      const setting = formatForm(values.value, connection.id);
      await createUpdateSetting(setting);
    },
    [connection.id, createUpdateSetting],
  );

  const labelContent = useMemo(
    () => (
      <>
        {t("translation:database_qualifier")}
        <IconToolTip
          content={t("translation:database_qualifier_description")}
          className="ml-1"
        />
      </>
    ),
    [t],
  );
  return (
    <div>
      <h2>{t("translation:other_settings")}</h2>

      <div>
        <Formik
          enableReinitialize
          initialValues={{
            value: settingValue,
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, dirty }) => (
            <Form layout="vertical">
              <Row justify="start" gutter={16}>
                <Col span={11}>
                  <Form.Item name="databaseQualifier" label={labelContent}>
                    <Input name="url" placeholder={"PFWF5013"} size="large" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <SubmitButton
                    type="primary"
                    size="large"
                    block
                    style={{ marginTop: "30px" }}
                    disabled={isSubmitting || !dirty}
                  >
                    {t("translation:save")}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

import { Col, Row } from "antd";
import { iField, Integration } from "/app/src/models";
import { useTranslation } from "react-i18next";
import HeroDivider from "/app/src/components/HeroUi/Divider";
import Box from "/app/src/components/generic/components/box";
import Field from "./field";
import NewField from "./newField";
import { orderBuilderFields as allFields } from "/app/src/constants/orderBuilderFields";
import { useMemo } from "react";

/**
 * Renders the order fields component.
 *
 * @param integration - The integration object.
 * @param orderFields - The array of order fields.
 * @param createField - The function to create a new field.
 * @param updateField - The function to update an existing field.
 */
export default function OrderFields({
  integration,
  orderFields,
  createField,
  updateField,
  deleteField,
}: {
  integration: Integration;
  orderFields: iField[];
  createField: (data: iField) => Promise<void>;
  updateField: (data: iField) => Promise<void>;
  deleteField: (data: iField) => Promise<iField>;
}) {
  const { t } = useTranslation();
  const requiredFields = useMemo(
    () => [{ label: "Order Name", value: "name" }],
    [],
  );
  const existingFields = orderFields.map((field) => field.name);
  const missingRequiredFields = requiredFields.filter(
    (requiredField) => !existingFields.includes(requiredField.value),
  );

  const fieldsToRender = useMemo(() => {
    return [
      ...orderFields.map((field) => {
        // Check if the field is in requiredFields
        const isRequired = requiredFields.some(
          (requiredField) => requiredField.value === field.name,
        );
        if (isRequired) {
          return {
            ...field,
            deletable: false,
          };
        }
        return { ...field, deletable: true };
      }),
      ...missingRequiredFields.map((field) => ({
        name: field.value,
        label: field.label,
        deletable: false,
      })),
    ];
  }, [orderFields, missingRequiredFields, requiredFields]);

  const fieldsNotInRender = useMemo(() => {
    return allFields.filter(
      (field) =>
        !fieldsToRender.some(
          (renderedField) => renderedField.name === field.value,
        ),
    );
  }, [fieldsToRender]);
  return (
    <Box>
      <Row className="pb-3">
        <Col className="text-lg" span={8}>
          {t("translation:order_field")}
        </Col>
      </Row>
      <HeroDivider />
      {fieldsToRender.map((field) => {
        return (
          <Field
            key={field.name}
            iField={field}
            integration={integration}
            updateField={updateField}
            createField={createField}
            level={"order"}
            deleteField={deleteField}
          />
        );
      })}
      <NewField
        fields={fieldsNotInRender}
        createField={createField}
        level={"order"}
      />
    </Box>
  );
}

import { useState, useCallback } from "react";
import { Row, Col, Select } from "antd";
import HeroSpin from "/app/src/components/HeroUi/Spin";
import {
  snapshotService,
  reportService,
  snapshotDataService,
} from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Report } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import BarChart from "/app/src/components/charts/barChart";
import { useQuery } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import LineChart from "/app/src/components/charts/lineChart";
import HeroButton from "/app/src/components/HeroUi/Button";

export default function SnapshotChart({ report }: { report: Report }) {
  const { t } = useTranslation();
  // Count of Snapshots
  const [chartType, setChartType] = useState("bar");
  const [selectedSnapshotId, setSelectedSnapshotId] = useState(
    report.defaultSnapshotId,
  );

  const updateSelectedSnapshotId = useCallback(
    (snapshotId) => {
      setSelectedSnapshotId(snapshotId);
      reportService.updateSingle(report.id, {
        defaultSnapshotId: snapshotId,
      });
    },
    [report.id],
  );

  const { data: snapshots } = useQuery({
    queryKey: ["snapshots", report.id],
    queryFn: () => {
      return snapshotService.getAll(buildParams({ reportId: report.id }));
    },
    select: (response) => response.snapshots,
    retry: false,
    placeholderData: { snapshots: [] },
  });
  const handleChartTypeChange = useCallback((type) => {
    return () => {
      setChartType(type);
    };
  }, []);
  const { data, isFetching } = useQuery({
    queryKey: ["snapshotData", selectedSnapshotId, chartType],
    queryFn: () => {
      return snapshotDataService
        .getAll(selectedSnapshotId, buildParams({ chartType, refresh: true }))
        .then(handlePromiseError);
    },
    select: (response) => {
      return response.data;
    },
    retry: false,
    placeholderData: { data: [] },
    keepPreviousData: true,
    refetchInterval: 1000 * 60, // 1 minute
    staleTime: 1000 * 60,
  });
  if (isFetching) {
    // return centered loading spinner
    return <HeroSpin />;
  }

  return snapshots.length > 0 ? (
    <div className="snapshotChart">
      {selectedSnapshotId &&
        (chartType === "bar" ? (
          <BarChart data={data} isStacked={false} trendLines={[]} />
        ) : chartType === "line" ? (
          <LineChart data={data} trendLines={[]} />
        ) : null)}

      <div className="chartControls">
        <Row justify="start" gutter={16}>
          <Col span={5}>
            <Select
              size="large"
              placeholder={t("translation:pick") + t("translation:_a_chart")}
              defaultValue={selectedSnapshotId}
              className="fullWidth" // skipcq: JS-0394
              onChange={updateSelectedSnapshotId}
            >
              {snapshots.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <HeroButton
              color={chartType === "bar" ? "primary" : "default"}
              variant="bordered"
              size="md"
              onClick={handleChartTypeChange("bar")}
              className="mr-2"
            >
              {t("translation:bar")}
            </HeroButton>
            <HeroButton
              color={chartType === "line" ? "primary" : "default"}
              variant="bordered"
              size="md"
              onClick={handleChartTypeChange("line")}
            >
              {t("translation:line")}
            </HeroButton>
          </Col>
        </Row>
      </div>
    </div>
  ) : null;
}

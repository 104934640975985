import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { parameterValueService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { ParameterValue as ParameterValueType } from "/app/src/models";
import Search from "../search";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
/**
 * Component for displaying all parameter values in a table
 */
export default function ParameterValues() {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const columnHelper = createColumnHelper<ParameterValueType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("stationName", {
        id: "stationName",
        cell: (info) => info.getValue(),
        header: t("translation:station"),
      }),
      columnHelper.accessor("value", {
        id: "value",
        cell: (info) => info.getValue(),
        header: t("translation:value"),
      }),
    ],
    [columnHelper, t],
  );

  const {
    data: parameterValues,
    count: parameterValuesCount,
    isFetching,
  } = usePaginatedData<ParameterValueType>({
    queryKey: ["parameter_values"],
    page,
    pageSize,
    searchString,
    sort,
    service: parameterValueService,
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:parameter_values")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={parameterValues}
        tableColumns={columns}
        length={parameterValuesCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        emptyText={t("translation:no_parameter_values_found")}
      />
    </div>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sort as SortType } from "/app/src/types";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  ColumnSort,
} from "@tanstack/react-table";
import Pagination from "./pagination";
import HeroSpin from "/app/src/components/HeroUi/Spin";

/**
 * Empty table component
 * @param emptyText
 */
export function TableEmpty({ emptyText }: { emptyText: string }) {
  return (
    <div className="reportLoading text-center">
      {emptyText && <span className="loadingText">{emptyText}</span>}
    </div>
  );
}

/**
 * Table component that uses React-Table v8
 * @param rows
 * @param tableColumns
 * @param length
 * @param sort
 * @param setSort
 * @param rowClicked
 * @param emptyText
 * @param enableRowSelection
 * @param paginationEnabled
 * @param loading
 * @param classStyle
 * @returns React-Table v8 table
 */
export default function Table({
  rows,
  tableColumns,
  length,
  sort,
  setSort,
  rowClicked,
  emptyText,
  enableRowSelection = false,
  paginationEnabled,
  loading = false,
  classStyle = "dataTable",
  setSelectedRows = () => {},
  clearSelectedRows = false,
}: {
  rows: any[];
  tableColumns: ColumnDef<any>[];
  length: number;
  sort: ColumnSort[];
  setSort: React.Dispatch<React.SetStateAction<SortType>>;
  rowClicked?: (key: number) => void;
  emptyText: string;
  loading?: boolean;
  enableRowSelection?: boolean | ((row) => boolean);
  paginationEnabled?: {
    setPageSize: (pageSize: number) => void;
    setPage: (page: number) => void;
    currentPage: number;
    pageSize: number;
  };
  classStyle?: string;
  setSelectedRows?: (rows?: Record<string, boolean>) => void;
  clearSelectedRows?: boolean;
}) {
  const data = useMemo(() => rows, [rows]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const { t } = useTranslation();
  const hover = rowClicked ? true : false;
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sort,
      rowSelection,
    },
    onSortingChange: setSort,
    onRowSelectionChange: setRowSelection,
    enableSorting: !loading,
    manualSorting: true,
    enableRowSelection,
    enableColumnResizing: true,
  });

  useEffect(() => {
    if (setSelectedRows) {
      // skipcq: JS-W1038
      setSelectedRows(rowSelection);
    }
  }, [rowSelection, setSelectedRows, table]);

  //meta tag is used to determine if the column is clickable
  const onSubmitHandler = useCallback(
    (rowId: number, cell) => () => {
      if (cell?.column?.columnDef?.meta?.clickable === false) {
        return;
      }
      rowClicked?.(rowId);
      setSelectedRow(rowId);
    },
    [rowClicked],
  );

  // when the clear selected rows prop is changed, clear the selected rows
  useEffect(() => {
    setRowSelection({});
  }, [clearSelectedRows, setRowSelection]);

  return (
    <>
      <div className={classStyle}>
        {length === null || loading ? (
          <div className="flex justify-center p-10 h-[400px]">
            <HeroSpin size="lg" />
          </div>
        ) : length === 0 ? (
          <TableEmpty emptyText={emptyText} />
        ) : tableColumns.length === 0 ? (
          <TableEmpty emptyText={t("translation:no_columns_selected")} />
        ) : (
          <table className="table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={
                        header.column?.columnDef?.meta?.sortable !== false
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{ asc: "🔼", desc: "🔽" }[
                        header.column.getIsSorted() as string
                      ] ?? null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  className={`${selectedRow === row.original.id && rowClicked ? "bg-info-accent cursor-pointer" : hover ? "hover:bg-info-muted cursor-pointer" : ""}`}
                  key={row.original.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    // skipcq: JS-0746, JS-0760
                    <td
                      key={cell.id}
                      onClick={onSubmitHandler(row.original.id, cell)}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {paginationEnabled && (
        <Pagination
          length={length}
          pageSize={paginationEnabled.pageSize}
          setPageSize={paginationEnabled.setPageSize}
          currentPage={paginationEnabled.currentPage}
          setPage={paginationEnabled.setPage}
        />
      )}
    </>
  );
}

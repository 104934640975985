import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useDisclosure } from "@heroui/react";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

const DeleteButton = ({
  onClick,
  disabled,
  modalHeader,
  modalBody,
}: {
  onClick: () => void;
  disabled?: boolean;
  modalHeader?: string;
  modalBody?: string;
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return modalHeader ? (
    <>
      <button
        className="bg-none cursor-pointer p-2 border-0 hover:opacity-100"
        onClick={onOpen}
        disabled={disabled}
      >
        <IconBuilder icon="Delete" />
      </button>
      <HeroModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader,
          modalBody,
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
              >
                Cancel
              </HeroButton>
              <HeroButton size="sm" color="red" onClick={onClick}>
                Delete
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </>
  ) : (
    <button
      className="bg-none cursor-pointer p-2 border-0 hover:opacity-100"
      onClick={onClick}
      disabled={disabled}
    >
      <IconBuilder icon="Delete" />
    </button>
  );
};

export default DeleteButton;

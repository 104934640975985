import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers/params";
import getOrderByQuery from "/app/src/helpers/table";
import { ColumnSort } from "@tanstack/react-table";
import { useCallback } from "react";

type PaginatedService<TItem, TParams = URLSearchParams> = {
  getAll: (params?: TParams) => Promise<{
    [key: string]: TItem[];
  }>;
  getCount: (params?: TParams) => Promise<{
    count: number;
  }>;
};

/**
 * A hook to manage and fetch paginated data using a given service.
 *
 * @param {Object} params - The parameters for fetching paginated data.
 * @param {string[]} params.queryKey - Array of strings to uniquely identify the query.
 * @param {string} [params.searchString=""] - Optional search string for filtering results.
 * @param {number} [params.page] - The current page number for pagination.
 * @param {number} [params.pageSize] - The number of items per page.
 * @param {ColumnSort[]} [params.sort] - Array of sorting options.
 * @param {PaginatedService<T, URLSearchParams>} params.service - The service to fetch data from.
 * @param {Record<string, unknown>} [params.options={}] - Additional options for the query.
 * @param {number | false} [params.refetchInterval=false] - Interval for refetching data automatically.
 *
 * @returns {Object} An object containing paginated data, count, fetching status, a refetch function, and query keys.
 */
export default function usePaginatedData<T>({
  queryKey,
  searchString = "",
  page,
  pageSize,
  sort,
  service,
  options = {},
  refetchInterval = false,
}: {
  queryKey: string[];
  searchString?: string;
  page?: number;
  pageSize?: number;
  sort?: ColumnSort[];
  service: PaginatedService<T, URLSearchParams>;
  options?: Record<string, unknown>;
  refetchInterval?: number | false;
}) {
  const {
    data,
    isFetching,
    refetch: refetchData,
  } = useQuery({
    queryKey: [...queryKey, searchString, page, pageSize, sort, options],
    queryFn: () =>
      service.getAll(
        buildParams({
          limit: pageSize,
          page,
          search: searchString,
          orderby: getOrderByQuery(sort),
          ...options,
        }),
      ),
    initialData: { [queryKey[0]]: [] },
    select: (response: { [key: string]: T[] }) => response[queryKey[0]],
    refetchInterval,
  });

  const { data: countData, refetch: refetchCount } = useQuery({
    queryKey: [...queryKey, "count", searchString, options],
    queryFn: () =>
      service.getCount(
        buildParams({
          search: searchString,
          ...options,
        }),
      ),
    initialData: { count: 0 },
    select: (response: { count: number }) => response.count,
    refetchInterval,
  });

  const refetch = useCallback(() => {
    refetchData();
    refetchCount();
  }, [refetchData, refetchCount]);

  const getQueryKeys = useCallback(() => {
    return {
      dataQueryKey: [...queryKey, searchString, page, pageSize, sort, options],
      countQueryKey: [...queryKey, "count", searchString, options],
    };
  }, [queryKey, searchString, page, pageSize, sort, options]);

  return {
    data,
    count: countData,
    isFetching,
    refetch,
    getQueryKeys,
  };
}

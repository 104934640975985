import { useCallback, useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Form, InputNumber } from "formik-antd";
import { Row, Checkbox } from "antd";
import { reportService } from "/app/src/services";
import { Report } from "/app/src/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";
import { useTranslation } from "react-i18next";

interface FormValues {
  reportLimit: number | undefined;
}

/**
 * Component that renders a limit control for a report.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Report} props.report - The report object containing the limit.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <Limit report={report} />
 *
 * @remarks
 * This component uses Formik for form handling and react-query for data fetching and mutation.
 * It allows the user to set a limit on the report and updates the report accordingly.
 *
 * @dependencies
 * - useQueryClient from react-query
 * - useState, useEffect, useCallback from react
 * - useTranslation from react-i18next
 * - Formik, FormikProps from formik
 * - InputNumber, Checkbox, Row from antd
 * - simpleSchemaBuilder for validation schema
 * - reportService for updating the report
 * - handlePromiseError for error handling
 */
export default function Limit({ report }: { report: Report }) {
  const queryClient = useQueryClient();
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (report.reportLimit !== null) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [report.reportLimit]);

  const editTitleForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ submitForm }) => {
        return (
          <Form>
            <Form.Item name="reportLimit" hasFeedback={false} className="!mb-0">
              {isChecked && (
                <InputNumber
                  className="!ml-1"
                  name="reportLimit"
                  min={1}
                  max={1000}
                  onChange={submitForm}
                  size="small"
                  disabled={!isChecked}
                />
              )}
            </Form.Item>
          </Form>
        );
      },
      [isChecked],
    );

  const { mutateAsync: updateReport } = useMutation({
    mutationFn: (data: {
      [key: string]: string | number | boolean | Date | undefined;
    }) => reportService.updateSingle(report.id, data).then(handlePromiseError),
    onSuccess: (data) => {
      queryClient.setQueryData(["report", report.id], data);
      queryClient.invalidateQueries(["reportRowsCount", report.id]);
      queryClient.invalidateQueries(["reportRows", report.id]);
      queryClient.invalidateQueries([
        "snapshotData",
        report.defaultSnapshotId,
        "bar",
      ]);
      queryClient.invalidateQueries([
        "snapshotData",
        report.defaultSnapshotId,
        "line",
      ]);
    },
  });
  const handleChange = useCallback(
    (e) => {
      if (e.target.checked) {
        updateReport({ reportLimit: 100 });
      } else {
        updateReport({ reportLimit: null });
      }
    },
    [updateReport],
  );

  const updateLimitHandler = useCallback(
    async (values) => {
      return await updateReport({ reportLimit: values.reportLimit });
    },
    [updateReport],
  );

  return (
    <Row>
      <h4>{t("translation:limit")}:</h4>
      <Checkbox className="!ml-1" onChange={handleChange} checked={isChecked} />

      <Formik
        component={editTitleForm}
        enableReinitialize
        initialValues={{
          reportLimit: report.reportLimit,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "reportLimit", type: "number", required: false },
        ])}
        onSubmit={updateLimitHandler}
      />
    </Row>
  );
}

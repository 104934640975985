import { Formik, FormikHelpers, FormikProps } from "formik";
import { Connection } from "/app/src/models";
import { useCallback } from "react";
import { Form, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import useSetting from "../setting";
import PasswordField from "/app/src/components/generic/components/passwordField";

interface FormValues {
  appKey?: string;
  accessKey?: string;
  secretKey?: string;
}

/**
 * Hmac component for managing HMAC settings.
 *
 * This component provides a form for updating HMAC-related settings such as
 * appKey, accessKey, and secretKey. It uses the Formik library for form state
 * management and validation.
 *
 * @param {Object} props - The component props.
 * @param {Connection} props.connection - The connection object containing the connection ID.
 *
 * @returns {JSX.Element} The rendered Hmac component.
 *
 * @example
 * <Hmac connection={connection} />
 */
export default function Hmac({ connection }: { connection: Connection }) {
  const { setting: appKey, createUpdateSetting: createUpdateAppKey } =
    useSetting({
      connectionId: connection.id,
      name: "hmacAppKey",
    });

  const { setting: accessKey, createUpdateSetting: createUpdateAccessKey } =
    useSetting({
      connectionId: connection.id,
      name: "hmacAccessKey",
    });
  const { setting: secretKey, createUpdateSetting: createUpdateSecretSpec } =
    useSetting({
      connectionId: connection.id,
      name: "hmacSecretKey",
    });

  const updateSettings = useCallback(
    async (values: FormValues) => {
      /**
       * Updates a specified field if the new value is different from the current value.
       * Constructs an update payload and calls the provided update function with it.
       *
       * @param type - The type of the field
       * @param currentValue - The current value of the field.
       * @param newValue - The new value to update the field to.
       * @param updateFunction - The function to call with the update payload.
       * @param name - Optional name to include in the update payload.
       * @returns A promise that resolves when the update function completes.
       */
      const updateField = async (
        type: string,
        currentValue: string | undefined,
        newValue: string | undefined,
        updateFunction: (values) => Promise<void>,
        name?: string,
      ) => {
        if (currentValue !== newValue) {
          const updatePayload = {
            type,
            connectionId: connection.id,
            value: newValue,
          };
          if (name) {
            updatePayload["name"] = name;
          }
          await updateFunction(updatePayload);
        }
      };

      // Handle all updates in one place
      await Promise.all([
        updateField(
          "password",
          "",
          values.appKey,
          createUpdateAppKey,
          "hmacAppKey",
        ),
        updateField(
          "password",
          "",
          values.accessKey,
          createUpdateAccessKey,
          "hmacAccessKey",
        ),
        updateField(
          "password",
          "",
          values.secretKey,
          createUpdateSecretSpec,
          "hmacSecretKey",
        ),
      ]);

      return Promise.resolve();
    },
    [
      createUpdateAppKey,
      createUpdateAccessKey,
      createUpdateSecretSpec,
      connection.id,
    ],
  );
  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await updateSettings(values).then(() => {
        actions.resetForm();
      });
    },
    [updateSettings],
  );
  const { t } = useTranslation();
  const hmacForm: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form layout="vertical">
        <h3>{t("translation:settings")}</h3>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item name="accessKey" label={t("translation:access_key")}>
              <PasswordField
                name="accessKey"
                passwordExists={Boolean(accessKey?.id)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="secretKey" label={t("translation:secret_key")}>
              <PasswordField
                name="secretKey"
                passwordExists={Boolean(secretKey?.id)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="appKey" label={t("translation:app_key")}>
              <PasswordField
                name="appKey"
                passwordExists={Boolean(appKey?.id)}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [accessKey?.id, appKey?.id, secretKey?.id, t],
  );
  return (
    <Formik
      component={hmacForm}
      initialValues={{
        appKey: "",
        accessKey: "",
        secretKey: "",
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    />
  );
}

export const themes = [
  { name: "API Call", value: "APICall", id: "APICall" },
  { name: "Batch", value: "Batch", id: "Batch" },
  { name: "Batch Line", value: "WorkOrderLine", id: "WorkOrderLine" },
  { name: "Bin", value: "Bin", id: "Bin" },
  { name: "Carrier", value: "Carrier", id: "Carrier" },
  { name: "Cost Center", value: "CostCenter", id: "CostCenter" },
  { name: "Client", value: "Client", id: "Client" },
  { name: "History", value: "History", id: "History" },
  {
    name: "History Order",
    value: "HistoryMasterOrder",
    id: "HistoryMasterOrder",
  },
  {
    name: "History Order Line",
    value: "HistoryMasterOrderLine",
    id: "HistoryMasterOrderLine",
  },
  { name: "Job", value: "Job", id: "Job" },
  { name: "Job Line", value: "JobLine", id: "JobLine" },
  { name: "Kit", value: "Kit", id: "Kit" },
  { name: "Kit Line", value: "KitLine", id: "KitLine" },
  { name: "Location", value: "Location", id: "Location" },
  { name: "Location Content", value: "LocationContent", id: "LocationContent" },
  {
    name: "Location Content Breakdown",
    value: "LocationContentBreakdown",
    id: "LocationContentBreakdown",
  },
  { name: "Log", value: "Log", id: "Log" },
  { name: "Material", value: "Material", id: "Material" },
  { name: "Material Family", value: "MaterialFamily", id: "MaterialFamily" },
  { name: "Order", value: "MasterOrder", id: "MasterOrder" },
  { name: "Order Line", value: "MasterOrderLine", id: "MasterOrderLine" },
  { name: "Shelf", value: "Shelf", id: "Shelf" },
  { name: "Storage Rule", value: "StorageRule", id: "StorageRule" },
  { name: "Storage Unit", value: "StorageUnit", id: "StorageUnit" },
  { name: "Task", value: "Task", id: "Task" },
  { name: "User", value: "PowerPickUser", id: "PowerPickUser" },
  { name: "Warehouse", value: "Warehouse", id: "Warehouse" },
  { name: "Zone", value: "Zone", id: "Zone" },
];

export const tables = [
  { name: "Access Point", value: "AccessPoint" },
  { name: "API Call", value: "APICall" },
  { name: "App", value: "App" },
  { name: "Batch", value: "Batch" },
  { name: "Batch Line", value: "WorkOrderLine" },
  { name: "Bin", value: "Bin" },
  { name: "Carrier", value: "Carrier" },
  { name: "Cost Center", value: "CostCenter" },
  { name: "Client", value: "Client" },
  { name: "History Order", value: "HistoryMasterOrder" },
  { name: "History Order Line", value: "HistoryMasterOrderLine" },
  { name: "History", value: "History" },
  { name: "Integration", value: "Integration" },
  { name: "Job", value: "Job" },
  { name: "Job Line", value: "JobLine" },
  { name: "Kit", value: "Kit" },
  { name: "Kit Line", value: "KitLine" },
  { name: "Location", value: "Location" },
  { name: "Location Content", value: "LocationContent" },
  { name: "Location Content Breakdown", value: "LocationContentBreakdown" },
  { name: "Log", value: "Log" },
  { name: "Material", value: "Material" },
  { name: "Material Family", value: "MaterialFamily" },
  { name: "Material Property", value: "MaterialProperty" },
  { name: "Order", value: "MasterOrder" },
  { name: "Order Line", value: "MasterOrderLine" },
  { name: "Power Pick User Group", value: "PowerPickUserGroup" },
  { name: "Shelf", value: "Shelf" },
  { name: "Storage Rule", value: "StorageRule" },
  { name: "Storage Unit", value: "StorageUnit" },
  { name: "Supplement", value: "Supplement" },
  { name: "Task", value: "Task" },
  { name: "User", value: "PowerPickUser" },
  { name: "Variance", value: "Variance" },
  { name: "Warehouse", value: "Warehouse" },
  { name: "Workflow", value: "Workflow" },
  { name: "Zone", value: "Zone" },
];

export const epicorThemes = [
  { name: "History", value: "History" },
  { name: "Log", value: "Log" },
  { name: "Kit", value: "Kit" },
  { name: "Kit Line", value: "KitLine" },
  { name: "Material", value: "Material" },
  { name: "Material Family", value: "MaterialFamily" },
  { name: "Material Property", value: "MaterialProperty" },
  { name: "Master Order", value: "MasterOrder" },
  { name: "Master Order Line", value: "MasterOrderLine" },
  { name: "Storage Rule", value: "StorageRule" },
  { name: "Storage Unit", value: "StorageUnit" },
  { name: "Task", value: "Task" },
];

import { iOrder as iOrderType, iField } from "/app/src/models";
import { Col, Row } from "antd";
import { Select, Form } from "formik-antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

/**
 * Represents a LocationType component configuration.
 *
 * @param {number} iOrder - The order index for location.
 * @param {Array} orderFields - Fields related to the order.
 * @param {Object} locationInitialData - Initial data for location setup.
 */
export const LocationType = ({
  iOrder,
  orderFields,
  locationInitialData,
}: {
  iOrder: iOrderType;
  orderFields: iField[];
  locationInitialData: object;
}) => {
  const { t } = useTranslation();
  const locationKeys = Object.keys(locationInitialData);

  const isDisabled = useCallback(
    (locationKey) => {
      if (iOrder.orderSent) {
        return true;
      }
      const field = orderFields.find((f) => f.name === locationKey);
      return field?.viewOnly;
    },
    [iOrder.orderSent, orderFields],
  );

  return (
    <Row gutter={16}>
      {locationKeys.map((key) => {
        if (key.endsWith("_type")) {
          return (
            <Col span={4} key={key}>
              <Form.Item name={key} label={t(`translation:${key}`, key)}>
                <Select size="large" name={key} disabled={isDisabled(key)}>
                  <Select.Option value="id">Id</Select.Option>
                  <Select.Option value="name">Name</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          );
        } else {
          return (
            <Col span={20} key={key}>
              <Form.Item name={key} label={t(`translation:${key}`, key)}>
                <Select
                  size="large"
                  name={key}
                  mode="tags"
                  disabled={isDisabled(key)}
                />
              </Form.Item>
            </Col>
          );
        }
      })}
    </Row>
  );
};

import { Checkbox } from "formik-antd";
import { useFormikContext } from "formik";
import { useState } from "react";

interface FormValues {
  name: string;
  number: boolean;
  value: string;
}

/**
 * HeaderCheckbox component renders a checkbox that is disabled if it was initially checked.
 */
export default function HeaderCheckbox() {
  const { values } = useFormikContext<FormValues>();
  //store the initial value of the checkbox
  const [isInitiallyChecked] = useState(values.number);

  return <Checkbox disabled={isInitiallyChecked} name="number" />;
}

import { useState, useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { ReportColumn as ReportColumnT, ReportFilter } from "/app/src/models";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportColumnService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { IconBuilder } from "../../icons/IconBuilder";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
interface FormValues {
  name: string | undefined;
  filteringValue: string | undefined;
}

export const ReportColumn = ({
  column,
  canEdit,

  updateColumn,
}: {
  column: ReportColumnT;
  canEdit: boolean;

  updateColumn: (updatedCol: ReportColumnT) => Promise<any>;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [editingName, setEditingName] = useState(false);
  const { t } = useTranslation();

  const handleEditClick = useCallback((): void => {
    setEditingName(true);
  }, []);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteColumn } = useMutation({
    mutationFn: (columnId: number) => {
      return reportColumnService.deleteSingle(columnId);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["reportColumns", column.reportId],
        (oldData: { report_columns: ReportColumnT[] }) => {
          return {
            report_columns: oldData.report_columns.filter(
              (col) => col.id !== column.id,
            ),
          };
        },
      );
      // Remove any filters that use this column
      queryClient.setQueryData(
        ["reportFilters", column.reportId],
        (oldData: { report_filters: ReportFilter[] }) => {
          return {
            report_filters: oldData.report_filters.filter(
              (filter) => filter.reportColumnId !== column.id,
            ),
          };
        },
      );
    },
  });

  const onSubmitHandler = useCallback(
    async (values: FormValues) => {
      return await updateColumn({ id: column.id, ...values }).then(() => {
        setEditingName(false);
      });
    },
    [column.id, updateColumn],
  );

  const deleteReportColumnHandler = useCallback(() => {
    deleteColumn(column.id);
  }, [column.id, deleteColumn]);

  const columnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      () => (
        <Form className="">
          <div className="flex">
            {column.custom === "function" ? (
              <>
                <div>
                  <Form.Item name="name" hasFeedback={false}>
                    <Input type="text" name="name" />
                  </Form.Item>
                </div>
                <div className="ml-2">
                  <Form.Item name="filteringValue" hasFeedback={false}>
                    <Input
                      type="text"
                      name="filteringValue"
                      placeholder={t("translation:enter_filter_value")}
                    />
                  </Form.Item>
                </div>
              </>
            ) : (
              <div className="">
                <Form.Item name="name" hasFeedback={false}>
                  <Input type="text" name="name" />
                </Form.Item>
              </div>
            )}
            {column.type !== "count" && (
              <div className="ml-2">
                <Form.Item name="aggregate" hasFeedback={false}>
                  <Select
                    name="aggregate"
                    placeholder={t("translation:select_aggregate")}
                  >
                    {[
                      { value: "first", label: "First" },
                      { value: "last", label: "Last" },
                      { value: "sum", label: "Sum" },
                      { value: "mean", label: "Mean" },
                      { value: "min", label: "Min" },
                      { value: "max", label: "Max" },
                      { value: "median", label: "Median" },
                    ].map((agg) => (
                      <Select.Option value={agg.value} key={agg.value}>
                        {agg.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            <div className="flex justify-center">
              <SubmitButton
                icon={<IconBuilder icon="Checkmark" color="#ffffff" />}
                type="primary"
                className="ml-2 px-5"
                block
              />
            </div>
          </div>
        </Form>
      ),
      [column.custom, column.type, t],
    );
  return (
    <div
      ref={setNodeRef}
      style={style}
      className="border border-neutral-fog rounded mb-xsmall flex items-center justify-between px-3"
    >
      <div className="flex items-center">
        <div {...attributes} {...listeners} className="cursor-move mr-2">
          <IconBuilder icon="Drag" />
        </div>
        <div>
          {!editingName ? (
            <div style={{ paddingTop: "8px" }}>{column.title}</div>
          ) : (
            <div style={{ paddingTop: "15px" }} />
          )}
          {editingName ? (
            <Formik
              component={columnForm}
              enableReinitialize
              initialValues={{
                name: column.name,
                filteringValue: column?.filteringValue,
                aggregate: column?.aggregate || "first",
              }}
              validationSchema={simpleSchemaBuilder([
                { name: "name", type: "string", required: true },
              ])}
              onSubmit={onSubmitHandler}
            />
          ) : (
            <div className="flex items-center">
              <h3 className="mb-0">{column.name}</h3>
              <div className="ml-2">
                <EditButton onClick={handleEditClick} disabled={!canEdit} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mr-3">
        <DeleteButton onClick={deleteReportColumnHandler} disabled={!canEdit} />
      </div>
    </div>
  );
};

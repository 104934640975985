import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

/**
 *
 * @param param0 versionChanged
 * @returns Notification for new version
 */
export default function NewVersionStatus({
  versionChanged,
}: {
  versionChanged: boolean;
}) {
  const { t } = useTranslation();

  const reloadClick = useCallback(() => {
    window.location.reload();
  }, []);

  const openToast = useCallback(() => {
    toast.warning(t("translation:license_version_message"), {
      classNames: {
        actionButton: "!bg-[#DC7609]",
      },
      position: "top-right",
      action: {
        label: "Refresh",
        onClick: () => {
          reloadClick();
        },
      },
      duration: Infinity,
      closeButton: true,
    });
  }, [reloadClick, t]);

  if (versionChanged) {
    openToast();
  }
  return null;
}

import { useTranslation } from "react-i18next";
import { Integration, Mapping } from "/app/src/models";
import NewFilterForm from "./newFilterForm";
import { useQuery } from "@tanstack/react-query";
import { mappingService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import Filter from "./filter";
import useSettings from "/app/src/components/settings/setting/useSettings";
import HeroButton from "/app/src/components/HeroUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useToggle } from "/app/src/hooks";
import { Col, Row } from "antd";

/**
 * Component to display filters for an integration
 */
export default function Filters({ integration }: { integration: Integration }) {
  const {
    settings: filters,
    createSetting,
    updateSetting,
    deleteSetting,
  } = useSettings({ type: "sqlFilter", integrationId: integration.id });
  const [toggled, setToggled] = useToggle();
  const { t } = useTranslation();
  //get all mappings with the integration id
  const { data: mappings } = useQuery({
    queryKey: ["dataPullMappings", integration.id],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({
          integrationId: integration.id,
        }),
      );
    },
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  return (
    <>
      <Row className="pb-3">
        <Col className="text-lg font-semibold" span={8}>
          {t("translation:sql_filtering")}
        </Col>
      </Row>
      {filters.map((filter) => (
        <Filter
          filter={filter}
          updateSetting={updateSetting}
          deleteSetting={deleteSetting}
          mappings={mappings}
          key={filter.id}
        />
      ))}
      {toggled && (
        <NewFilterForm
          integration={integration}
          createSetting={createSetting}
          mappings={mappings}
        />
      )}
      <HeroButton
        variant={!toggled ? "solid" : "bordered"}
        color="primary"
        onClick={setToggled}
      >
        {!toggled ? (
          <span className="flex justify-between items-center">
            {t("translation:new_filter")}
            <IconBuilder
              className="ml-1"
              icon="ArrowRight"
              color="#ffffff"
              size={16}
            />
          </span>
        ) : (
          t("translation:cancel")
        )}
      </HeroButton>
    </>
  );
}

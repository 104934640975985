import React, { RefObject } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@heroui/react";
interface OverlayTriggerState {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

interface HeroPopoverProps {
  triggerContent: React.ReactNode;
  popoverContent: React.ReactNode;
  size?: "sm" | "md" | "lg";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  shadow?: "sm" | "md" | "lg";
  backdrop?: "transparent" | "opaque" | "blur";
  placement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  state?: OverlayTriggerState;
  isOpen?: boolean;
  defaultOpen?: boolean;
  offset?: number;
  containerPadding?: number;
  crossOffset?: number;
  showArrow?: boolean;
  shouldFlip?: boolean;
  triggerScaleOnOpen?: boolean;
  shouldBlockScroll?: boolean;
  isKeyboardDismissDisabled?: boolean;
  shouldCloseOnBlur?: boolean;
  motionProps?: object;
  portalContainer?: HTMLElement;
  updatePositionDeps?: any[];
  triggerRef?: RefObject<HTMLElement>;
  scrollRef?: RefObject<HTMLElement>;
  disableAnimation?: boolean;
  classNames?: object;
  onOpenChange?: () => void;
  shouldCloseOnInteractOutside?: (e: HTMLElement) => boolean;
  onClose?: () => void;
}

/**
 *
 * @param param0 triggerContent, popoverContent, size, color, radius, shadow, backdrop, placement, state, isOpen, defaultOpen, offset, containerPadding, crossOffset, showArrow, shouldFlip, triggerScaleOnOpen, shouldBlockScroll, isKeyboardDismissDisabled, shouldCloseOnBlur, motionProps, portalContainer, updatePositionDeps, triggerRef, scrollRef, disableAnimation, classNames, onOpenChange, shouldCloseOnInteractOutside, onClose
 * @returns NextUI Popover
 */
const HeroPopover: React.FC<HeroPopoverProps> = ({
  triggerContent,
  popoverContent,
  size = "md",
  color = "default",
  radius = "lg",
  shadow = "lg",
  backdrop = "transparent",
  placement = "bottom",
  state,
  isOpen,
  defaultOpen,
  offset = 7,
  containerPadding = 12,
  crossOffset = 0,
  showArrow = false,
  shouldFlip = false,
  triggerScaleOnOpen = true,
  shouldBlockScroll = false,
  isKeyboardDismissDisabled = false,
  shouldCloseOnBlur = false,
  motionProps,
  portalContainer,
  updatePositionDeps,
  triggerRef,
  scrollRef,
  disableAnimation = false,
  classNames,
  onOpenChange,
  shouldCloseOnInteractOutside,
  onClose,
}) => {
  return (
    <Popover
      size={size}
      color={color}
      radius={radius}
      shadow={shadow}
      backdrop={backdrop}
      placement={placement}
      state={state}
      isOpen={isOpen}
      defaultOpen={defaultOpen}
      offset={offset}
      containerPadding={containerPadding}
      crossOffset={crossOffset}
      showArrow={showArrow}
      shouldFlip={shouldFlip}
      triggerScaleOnOpen={triggerScaleOnOpen}
      shouldBlockScroll={shouldBlockScroll}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      shouldCloseOnBlur={shouldCloseOnBlur}
      motionProps={motionProps}
      portalContainer={portalContainer}
      updatePositionDeps={updatePositionDeps}
      triggerRef={triggerRef}
      scrollRef={scrollRef}
      disableAnimation={disableAnimation}
      classNames={classNames}
      onOpenChange={onOpenChange}
      shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
      onClose={onClose}
    >
      <PopoverTrigger>{triggerContent}</PopoverTrigger>
      <PopoverContent>{popoverContent}</PopoverContent>
    </Popover>
  );
};

export default HeroPopover;

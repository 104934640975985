import React, { useCallback } from "react";
import barIcon from "./barIcon.svg";
import pieIcon from "./pieIcon.svg";
import lineIcon from "./lineIcon.svg";
import numberIcon from "./numberIcon.svg";
import calendarIcon from "./calendarIcon.svg";
import stackedBarIcon from "./stackedBarIcon.svg";
import { Widget } from "/app/src/models";
import Title from "./title";
import Datasets from "./datasets";
import HeroButton from "/app/src/components/HeroUi/Button";
import PageHeader from "../../../generic/components/pageHeader";
import TrendLines from "./trendlines";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { widgetService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";
import HeroTip from "/app/src/components/HeroUi/Tip";
import HeroSelect from "/app/src/components/HeroUi/Select";

export default function EditWidget({ widget }: { widget: Widget }) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] =
    React.useState<string>("datasets");
  const queryClient = useQueryClient();
  const { mutateAsync: updateWidget } = useMutation({
    mutationFn: (values: Omit<Widget, "datasets">) => {
      return widgetService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["widgets", widget.viewId],
        (oldData: { widgets: Widget[] }) => {
          return {
            widgets: oldData.widgets.map((widget) => {
              if (widget.id === response.widget.id) {
                return response.widget;
              }
              return widget;
            }),
          };
        },
      );
    },
  });

  const { mutateAsync: deleteWidget } = useMutation({
    mutationFn: (id: number) => {
      return widgetService.deleteSingle(id).then(handlePromiseError);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["widgets", widget.viewId],
        (oldData: { widgets: Widget[] }) => {
          return {
            widgets: oldData.widgets.filter((w) => {
              return w.id !== widget.id;
            }),
          };
        },
      );
    },
  });

  const handleDeleteClick = useCallback((): void => {
    deleteWidget(widget.id);
  }, [deleteWidget, widget.id]);

  const handleChartTypeChange = useCallback(
    (type) => {
      return () => {
        updateWidget({ id: widget.id, chartType: type });
        //on chart change we need to refresh the data
        queryClient.invalidateQueries(["widgetData", widget.id]);
      };
    },
    [queryClient, updateWidget, widget.id],
  );

  /**
   * Updates the selected option: Either "datasets" or "trendlines"
   * @param value the value of the selected option
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(event.target.value);
    },
    [],
  );
  return (
    <div className="editWidget">
      <PageHeader
        title={<Title widget={widget} updateWidget={updateWidget} />}
        deleteFn={handleDeleteClick}
        modalHeader={t("translation:delete_widget")}
      />
      <div className="mt-3">
        <HeroTip size="sm" content={t("translation:bar_chart")}>
          <span>
            <HeroButton
              variant={widget.chartType === "bar" ? "flat" : "bordered"}
              color={widget.chartType === "bar" ? "primary" : "default"}
              size="sm"
              onClick={handleChartTypeChange("bar")}
              className="mr-1"
            >
              <img className="icon" src={barIcon} alt="bar" />
            </HeroButton>
          </span>
        </HeroTip>
        <HeroTip size="sm" content={t("translation:number")}>
          <span>
            <HeroButton
              variant={widget.chartType === "number" ? "flat" : "bordered"}
              color={widget.chartType === "number" ? "primary" : "default"}
              size="sm"
              onClick={handleChartTypeChange("number")}
              className="mr-1"
            >
              <img className="icon" src={numberIcon} alt="number" />
            </HeroButton>
          </span>
        </HeroTip>

        <HeroTip size="sm" content={t("translation:line_chart")}>
          <span>
            <HeroButton
              variant={widget.chartType === "line" ? "flat" : "bordered"}
              color={widget.chartType === "line" ? "primary" : "default"}
              size="sm"
              onClick={handleChartTypeChange("line")}
              className="mr-1"
            >
              <img className="icon" src={lineIcon} alt="line" />
            </HeroButton>
          </span>
        </HeroTip>
        <HeroTip size="sm" content={t("translation:pie_chart")}>
          <span>
            <HeroButton
              variant={widget.chartType === "pie" ? "flat" : "bordered"}
              color={widget.chartType === "pie" ? "primary" : "default"}
              size="sm"
              onClick={handleChartTypeChange("pie")}
              className="mr-1"
            >
              <img className="icon" src={pieIcon} alt="pie" />
            </HeroButton>
          </span>
        </HeroTip>
        <HeroTip size="sm" content={t("translation:stacked_bar_chart")}>
          <span>
            <HeroButton
              variant={widget.chartType === "stackedBar" ? "flat" : "bordered"}
              color={widget.chartType === "stackedBar" ? "primary" : "default"}
              size="sm"
              onClick={handleChartTypeChange("stackedBar")}
              className="mr-1"
            >
              <img className="icon" src={stackedBarIcon} alt="stackedBar" />
            </HeroButton>
          </span>
        </HeroTip>
        <HeroTip size="sm" content={t("translation:calendar_chart")}>
          <span>
            <HeroButton
              variant={widget.chartType === "calendar" ? "flat" : "bordered"}
              color={widget.chartType === "calendar" ? "primary" : "default"}
              size="sm"
              onClick={handleChartTypeChange("calendar")}
            >
              <img className="icon" src={calendarIcon} alt="calendar" />
            </HeroButton>
          </span>
        </HeroTip>
      </div>

      <div className="mt-3">
        <HeroSelect
          size="md"
          fullWidth={false}
          ariaLabel="Select datasets or trendlines"
          disallowEmptySelection
          onChange={handleChange}
          defaultSelectedKeys={["datasets"]}
          value={selectedOption}
          items={[
            {
              key: "datasets",
              value: "datasets",
              label: t("translation:snapshots"),
            },
            {
              key: "trendlines",
              value: "trendlines",
              label: t("translation:trend_lines"),
            },
          ]}
        />
      </div>
      {selectedOption === "datasets" && <Datasets widget={widget} />}
      {selectedOption === "trendlines" && <TrendLines widget={widget} />}
    </div>
  );
}

/**
 *
 * @param param0 items: { label: string; value: string | number }[]; bordered?: boolean; itemsPerRow?: number;
 * @returns Description component
 */
export default function Descriptions({
  items,
  bordered = false,
  itemsPerRow = 3,
}: {
  items: { label: string; value: string | number | JSX.Element }[];
  bordered?: boolean;
  itemsPerRow?: number;
}) {
  if (bordered) {
    const rows = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }

    return (
      <div className="py-4">
        <table className="w-full border">
          <tbody>
            {rows.map((row) => (
              <tr key={row.value} className="border">
                {row.map((item) => (
                  <>
                    <td className="p-4 font-semibold border bg-gray-100">
                      {item.label}
                    </td>
                    <td className="p-4 border">{item.value}</td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="grid grid-cols-3 gap-4">
        {items.map((item) => (
          <div
            key={item.label}
            className="col-span-1 flex justify-between pb-2"
          >
            <div className="mb-1 pr-1">
              <span>{item.label}: </span>
              <span className="text-sm font-normal">{item.value}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { processService } from "/app/src/services";
import { FormikProps } from "formik";
import SingleOrder from "./singleOrder";
import { useTranslation } from "react-i18next";
import { App } from "/app/src/models";
import HeroProgressBar from "/app/src/components/HeroUi/ProgressBar";
import HeroButton from "/app/src/components/HeroUi/Button";

interface FormValues {
  issueTo: string;
  qualification: string;
  facility: string;
  notes: string;
}
export default function ManualProcessingOrders({ app }: { app: App }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { integrationId } = useParams();
  const { processId } = useParams();
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [progress, setProgress] = useState(0);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const returnToOrderList = useCallback(() => {
    navigate(`/apps/${app.id}/integrations/${integrationId}`);
  }, [navigate, app, integrationId]);
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };
  useEffect(() => {
    if (processId) {
      processService.getSingle(processId).then((response) => {
        setOrders(response.process.orders);
      });
    }
  }, [app, processId]);

  useEffect(() => {
    let value = (currentOrder + 1) / orders.length;
    value = value * 100;
    setProgress(value);
  }, [currentOrder, orders]);

  const setOrder = useCallback(() => {
    handleSubmit();
    setCurrentOrder(currentOrder + 1);
  }, [currentOrder]);

  return (
    <div>
      {orders.length > 0 && (
        <SingleOrder
          orderId={orders[currentOrder]}
          cancelOrder={returnToOrderList}
          formRef={formRef}
        />
      )}
      <Row justify="center">
        <HeroProgressBar
          value={progress}
          color={progress === 100 ? "success" : "primary"}
        />
        {t("translation:order")} {currentOrder + 1} / {orders.length}
        {currentOrder === orders.length - 1 ? (
          <HeroButton
            className="next"
            color="primary"
            size="sm"
            fullWidth
            onClick={returnToOrderList}
          >
            {t("translation:finish_processing")}
          </HeroButton>
        ) : (
          <HeroButton
            className="next"
            color="primary"
            size="sm"
            fullWidth
            onClick={setOrder}
          >
            {t("translation:go_to_next_order")}
          </HeroButton>
        )}
      </Row>
    </div>
  );
}

import { Row, Col } from "antd";
import { materialService } from "/app/src/services";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  LocationContent as LocationContentType,
  Material as MaterialType,
} from "/app/src/models";
import HeroDivider from "/app/src/components/HeroUi/Divider";
export default function LocationContent({
  locationContent,
}: {
  locationContent: LocationContentType;
}) {
  const { t } = useTranslation();

  const { data: material } = useQuery({
    queryKey: ["material", locationContent.materialId],
    queryFn: () => {
      return materialService.getSingle(locationContent.materialId);
    },
    enabled: Boolean(locationContent.materialId),
    initialData: { material: {} },
    select: (data: { material: MaterialType }) => {
      return data.material;
    },
  });

  return (
    <Row>
      <Col span={6} className="bigItem">
        <h4>{t("translation:material")}</h4>
        <p>{material.name}</p>
      </Col>
      <Col span={6} className="bigItem">
        <h4>{t("translation:current_quantity")}</h4>
        <p>{locationContent.currentQuantity}</p>
      </Col>
      <Col span={6} className="bigItem">
        <h4>{t("translation:type")}</h4>
        <p>{locationContent.typeDescription}</p>
      </Col>
      <Col span={6} className="bigItem">
        <h4>{t("translation:count_date")}</h4>
        <PowerPickTimeZoneToLocal
          date={locationContent.countDate}
          format={"MMM DD, YYYY"}
        />
      </Col>
      <HeroDivider />
    </Row>
  );
}

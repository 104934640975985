import { string, array, object, mixed } from "yup";
import i18next from "/app/src/locales/config";

export const newMappingSchema = object().shape({
  type: string().required(i18next.t("translation:type_is_required")),
  key: mixed().when("type", {
    is: (type) => type !== "array",
    then: (schema) => schema.required(i18next.t("translation:key_required")),
  }),
  columnTypeId: array().when("type", {
    is: (type) => type === "column",
    then: (schema) =>
      schema
        .min(1, i18next.t("translation:required"))
        .required(i18next.t("translation:column_required")),
  }),
  dataType: string().when("type", {
    is: (type) => type === "reportColumn",
    then: (schema) =>
      schema.required(i18next.t("translation:data_type_required")),
  }),
  value: string().when("type", {
    is: (type) => type === "text",
    then: (schema) => schema.required(i18next.t("translation:value_required")),
  }),
});

/**
 * Schema for SQL column mapping validation.
 *
 * This schema validates the structure of an object that maps SQL columns.
 * It ensures that the required fields are present and meet specific criteria
 * based on the type of mapping.
 *
 * Fields:
 * - `type`: A required string that specifies the type of mapping.
 *   The error message for a missing type is localized.
 * - `key`: A required string that is validated based on the `type` field.
 *   If the type is "column", the key must be alphanumeric or an underscore.
 *   The error messages are localized.
 * - `columnTypeId`: An array that is required if the `type` is "column".
 *   It must contain at least one item. The error messages are localized.
 * - `dataType`: A required string if the `type` is "reportColumn".
 *   The error message is localized.
 * - `value`: A required string if the `type` is "text".
 *   The error message is localized.
 *
 * Localization is handled using the `i18next` library.
 */
export const sqlColumnMappingSchema = object().shape({
  type: string().required(i18next.t("translation:type_is_required")),
  key: string()
    .required(i18next.t("translation:key_required"))
    .when("type", {
      is: "column",
      then: (schema) =>
        schema.matches(
          /^[a-zA-Z0-9_]+$/,
          i18next.t("translation:key_must_be_alphanumeric_underscore"),
        ),
    }),
  columnTypeId: array().when("type", {
    is: (type) => type === "column",
    then: (schema) =>
      schema
        .min(1, i18next.t("translation:required"))
        .required(i18next.t("translation:column_required")),
  }),
  dataType: string().when("type", {
    is: (type) => type === "reportColumn",
    then: (schema) =>
      schema.required(i18next.t("translation:data_type_required")),
  }),
  value: string().when("type", {
    is: (type) => type === "text",
    then: (schema) => schema.required(i18next.t("translation:value_required")),
  }),
});

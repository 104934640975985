import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { servicesService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { StatusType } from "/app/src/contexts/types";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useDisclosure } from "@heroui/react";

/**
 * Component for displaying restart button
 * @returns JSX.Element
 *
 */
export default function RestartButton() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Ok handler for modal
   */
  const handleOk = useCallback(() => {
    setIsLoading(true);
    servicesService.restart().then(() => {
      queryClient.setQueryData(["status"], (oldData: StatusType) => {
        return {
          ...oldData,
          message: "restarting",
        };
      });
      navigate("/status/restart");
      //navigate
    });
  }, [navigate, queryClient, setIsLoading]);

  return (
    <>
      <HeroButton fullWidth color="red" size="sm" onClick={onOpen}>
        {t("translation:restart_itempath")}
      </HeroButton>
      <HeroModal isOpen={isOpen} onOpenChange={onOpenChange} placement="top">
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:restart_itempath"),
          modalBody: t("translation:confirm_restart"),
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
              >
                {t("translation:cancel")}
              </HeroButton>
              <HeroButton
                size="sm"
                color="red"
                onClick={handleOk}
                isLoading={isLoading}
              >
                {t("translation:restart")}
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import HeroDivider from "/app/src/components/HeroUi/Divider";
import { Integration } from "/app/src/models";
import GeneralSettings from "/app/src/components/apps/orderBuilder/settings/generalSettings";
import Fields from "./fields";
/**
 * Component to display the settings for a single Data Pull or Data Push Integration
 */
export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="font-bold text-base">{t("translation:settings")}</h3>
      <HeroDivider />
      <GeneralSettings integration={integration} />
      <Fields integration={integration} />
    </>
  );
}

import React, { useState, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { connectionService } from "/app/src/services";
import Controls from "./_controls";
import NewIntegration from "./newIntegrationForm";
import { useTranslation } from "react-i18next";
import { App, Connection } from "/app/src/models";
import { createColumnHelper } from "@tanstack/react-table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Table from "/app/src/components/generic/tables/table";
import DeleteButtonCell from "/app/src/components/generic/tables/deleteButtonCell";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
/**
 * Component for the Philips Hue app
 * @param app: Philips Hue app
 */
export default function Hue({ app }: { app: App }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [activeNewIntegration, setActiveNewIntegration] = useState(false);

  const {
    data: connections,
    count: connectionsCount,
    isFetching,
    refetch,
  } = usePaginatedData<Connection>({
    queryKey: ["connections"],
    page,
    pageSize,
    sort,
    service: connectionService,
    options: { type: "[or]Hue Local;Hue Remote" },
  });

  const refreshTable = useCallback(() => {
    refetch();
  }, [refetch]);

  const deleteHueConnection = useCallback((id: number) => {
    return connectionService.deleteSingle(id);
  }, []);

  const columnHelper = createColumnHelper<Connection>();
  //columns found in the table that holds the list of connected hubs
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => t("translation:name"),
      }),
      columnHelper.accessor("type", {
        id: "type",
        cell: (info) => info.getValue(),
        header: () => t("translation:access_type"),
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (info) => (
          <DeleteButtonCell
            id={info.getValue()}
            refresh={refreshTable}
            deleteFn={deleteHueConnection}
          />
        ),
        header: "",
        meta: {
          clickable: false,
          sortable: false,
        },
      }),
    ],
    [columnHelper, deleteHueConnection, refreshTable, t],
  );

  return (
    <div className="app epicorImport">
      <Helmet>
        <title>{t("translation:philips_hue")} - ItemPath</title>
      </Helmet>

      <Controls
        name={t("translation:philips_hue")}
        activeNewIntegration={activeNewIntegration}
        setActiveNewIntegration={setActiveNewIntegration}
        refresh={refreshTable}
      />
      <Row gutter={20}>
        <Col span={3}>
          <NewIntegration />
        </Col>
        <Col span={21}>
          <Table
            loading={isFetching}
            rows={connections}
            tableColumns={columns}
            length={connectionsCount}
            sort={sort}
            setSort={setSort}
            enableRowSelection={false}
            paginationEnabled={{
              currentPage: page,
              pageSize,
              setPage,
              setPageSize,
            }}
            emptyText={t("translation:no_bridges_connected")}
          />
        </Col>
      </Row>
    </div>
  );
}

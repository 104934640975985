import { useQuery } from "@tanstack/react-query";
import {
  warehouseService,
  callService,
  workflowService,
  snapshotService,
  reportService,
  fileService,
  connectionService,
  logService,
  servicesService,
} from "/app/src/services";
import { Log as LogType, Connection as ConnectionType } from "/app/src/models";
import { buildParams, getPreviousDaysDate } from "/app/src/helpers";
import { APICallBreakdown } from "./charts/apiCallBreakdown";

/**
 * Custom hook for the Status component
 * @returns warehouseCount, apiCallsByMonth, workflowsCount, snapshotCount, reportCount, fileCount
 *
 */
export default function StatusHook() {
  const { data: warehouseCount } = useQuery({
    queryKey: ["warehouseCount"],
    queryFn: () => {
      return warehouseService.getCount();
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  //No need to filter by creation date because API call already does that
  const { data: apiCallsByMonth } = useQuery({
    queryKey: ["apiCallsByMonth"],
    queryFn: () => {
      return callService.getCount(
        buildParams({
          countOnly: true,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: workflowsCount } = useQuery({
    queryKey: ["workflowsCount"],
    queryFn: () => {
      return workflowService.getCount(
        buildParams({
          countOnly: true,
          status: 1,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: snapshotCount } = useQuery({
    queryKey: ["snapshotCount"],
    queryFn: () => {
      return snapshotService.getCount(
        buildParams({
          countOnly: true,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: reportCount } = useQuery({
    queryKey: ["reportCount"],
    queryFn: () => {
      return reportService.getCount(
        buildParams({
          countOnly: true,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: fileCount } = useQuery({
    queryKey: ["fileCount"],
    queryFn: () => {
      return fileService.getCount(
        buildParams({
          countOnly: true,
          creationDate: `[gt]${getPreviousDaysDate(30)}`,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: powerPickSQLConnection } = useQuery({
    queryKey: ["powerPickSQLConnection", { type: "PowerPick SQL" }],
    queryFn: () => {
      return connectionService
        .getAll(buildParams({ type: "PowerPick SQL" }))
        .then((response) => {
          return { connection: response.connections[0] };
        });
    },
    initialData: { connection: {} as ConnectionType },
    select: (data) => data.connection,
  });

  //useQuery that returns connection status for PowerPick MSSQL
  const { data: powerPickMSSQL } = useQuery({
    queryKey: ["mssqlLogs"],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          connectionId: powerPickSQLConnection.id,
          creationDate: "[gt]lastDay",
          orderby: "creationDate:asc",
          limit: 48,
          type: "scheduledCheck",
        }),
      );
    },
    initialData: { logs: [] },
    enabled: powerPickSQLConnection?.id !== undefined,
    select: (data: { logs: LogType[] }) => {
      return data.logs.map((log) => {
        return {
          id: log.id,
          status: log.status === 0 ? 1 : 5,
          creationDate: log.creationDate,
          statusColor: log.status === 1 ? "#3CAF96" : "#EA3B3B",
        };
      });
    },
  });

  const { data: powerPickWebServicesConnection } = useQuery({
    queryKey: [
      "powerPickWebServicesConnection",
      { type: "PowerPick Web Services" },
    ],
    queryFn: () => {
      return connectionService
        .getAll(buildParams({ type: "PowerPick Web Services" }))
        .then((response) => {
          return { connection: response.connections[0] };
        });
    },
    initialData: { connection: {} as ConnectionType },
    select: (data) => data.connection,
  });

  const { data: powerPickWebServices } = useQuery({
    queryKey: ["webServicesLogs"],
    queryFn: () => {
      return logService.getAll(
        buildParams({
          connectionId: powerPickWebServicesConnection.id,
          creationDate: "[gt]lastDay",
          orderby: "creationDate:asc",
          limit: 48,
          type: "scheduledCheck",
        }),
      );
    },
    initialData: { logs: [] },
    enabled: powerPickWebServicesConnection?.id !== undefined,
    select: (data: { logs: LogType[] }) => {
      return data.logs.map((log) => {
        return {
          id: log.id,
          status: log.status === 0 ? 1 : 5,
          creationDate: log.creationDate,
          statusColor: log.status === 1 ? "#3CAF96" : "#EA3B3B",
        };
      });
    },
  });

  /**
   * useQuery that returns API call breakdown
   */
  const { data: apiConnectionCounts } = useQuery({
    queryKey: ["apiConnectionCounts"],
    queryFn: () => {
      return servicesService.getAPICallsBreakdown();
    },
    initialData: [],
    select: (data: APICallBreakdown[]) => {
      data.forEach((d) => {
        switch (d.id) {
          case 200:
            d["color"] = "#92DA6F";
            break;
          case 300:
            d["color"] = "#FAFA33";
            break;
          case 400:
            d["color"] = "#FF5733";
            break;
          case 500:
            d["color"] = "#FF0000";
            break;
          default:
            d["color"] = "#000000";
        }
      });
      return data;
    },
  });

  return {
    warehouseCount,
    apiCallsByMonth,
    workflowsCount,
    snapshotCount,
    reportCount,
    fileCount,
    powerPickMSSQL,
    powerPickWebServices,
    apiConnectionCounts,
  };
}

import { useNavigate } from "react-router-dom";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import { useDisclosure } from "@heroui/react";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useCallback } from "react";

export default function DeleteForm({
  workflowId,
}: {
  workflowId: number | undefined;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    workflowService.deleteSingle(workflowId).then(() => {
      navigate("/workflows");
    });
  }, [workflowId, navigate]);

  return (
    <>
      <HeroButton
        fullWidth
        size="sm"
        color="default"
        variant="bordered"
        onClick={onOpen}
        className="border-[#a12121] text-[#a12121] bg-white mb-[20px]"
      >
        {t("translation:delete")} {t("translation:workflow")}
      </HeroButton>
      <HeroModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:confirm_delete_workflow"),
          modalBody: t("translation:delete_workflows_tip"),
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:do_not_delete")}
              </HeroButton>
              <HeroButton size="sm" color="red" onClick={handleDelete}>
                {t("translation:delete")}
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </>
  );
}

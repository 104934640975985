import DataCache from "./dataCache";
import Formatting from "./formatting";
import HeroDivider from "/app/src/components/HeroUi/Divider";
import Box from "/app/src/components/generic/components/box";
import { Integration } from "/app/src/models";

/**
 *
 * @param param0 integration
 * @returns General settings for the integration
 */
export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  return (
    <Box>
      <Formatting integration={integration} />
      <HeroDivider className="mb-3" />
      <DataCache integration={integration} />
    </Box>
  );
}

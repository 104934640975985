import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Integration, iOrder } from "/app/src/models";
import IndeterminateCheckbox from "/app/src/components/generic/tables/indeterminateCheckbox";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import InnerControls from "./_innerControls";
import Table from "/app/src/components/generic/tables/table";
import { useQueryClient } from "@tanstack/react-query";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import { iOrderService } from "/app/src/services";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { useNavigate } from "react-router-dom";
import { ArrowCircleRight } from "/app/src/components/icons/icons";
import usePaginatedData from "/app/src/hooks/usePaginatedData";

/**
 * Component that displays a list of orders created through the Order Builder App
 * Orders can be filtered, searched, selected, updated and deleted
 */
export default function OrderBuildOrders({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper<iOrder>();
  const [searchString, setSearchString] = useState("");
  const [clearSelected, setClearSelected] = useState(false);

  const clearSelection = useCallback(() => {
    setClearSelected((prev) => !prev);
  }, []);
  const handleSearch = useCallback((value) => {
    setSearchString(value);
  }, []);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [statusIds, setStatusIds] = useState<string[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<Record<number, boolean>>(
    {},
  );
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const handleStatusFilterChange = useCallback(
    (values: string[]) => {
      setStatusIds(values);
      clearSelection();
    },
    [clearSelection],
  );

  const {
    data: orders,
    count: ordersCount,
    isFetching,
    getQueryKeys,
  } = usePaginatedData<iOrder>({
    queryKey: ["orders"],
    searchString,
    page,
    pageSize,
    sort,
    service: iOrderService,
    options: {
      integrationId: integration.id,
      statusId: `[or]${statusIds.join(";")}`,
    },
    refetchInterval: 10000,
  });

  const deleteOrders = useCallback(async () => {
    const { dataQueryKey, countQueryKey } = getQueryKeys();
    if (selectedOrderIds.length === 0) {
      return;
    }
    await Promise.all(
      selectedOrderIds.map(async (orderId) => {
        return await iOrderService.deleteSingle(orderId);
      }),
    );
    queryClient.invalidateQueries(dataQueryKey);
    queryClient.invalidateQueries(countQueryKey);
    clearSelection();
  }, [clearSelection, getQueryKeys, queryClient, selectedOrderIds]);

  const updateStatusIds = useCallback(
    async (statusId: number) => {
      const { dataQueryKey } = getQueryKeys();
      if (selectedOrderIds.length === 0) {
        return;
      }
      await Promise.all(
        selectedOrderIds.map(async (orderId) => {
          return await iOrderService.updateSingle(orderId, {
            statusId,
          });
        }),
      );

      queryClient.invalidateQueries(dataQueryKey);
      clearSelection();
    },
    [clearSelection, getQueryKeys, queryClient, selectedOrderIds],
  );

  useEffect(() => {
    const tempOrders: number[] = [];
    const keys = Object.keys(selectedOrders);
    keys.forEach((element) => {
      const currentOrder = orders.at(Number(element));
      if (currentOrder?.id) {
        tempOrders.push(currentOrder.id);
      }
    });
    setSelectedOrderIds(tempOrders);
  }, [selectedOrders, orders]);

  const columns = useMemo(() => {
    /*
     * Returns function that handles click event
     */
    const handleClick = (id: number) => {
      return () =>
        navigate(
          `/apps/${integration.appId}/integrations/${integration.id}/orders/${id}`,
        );
    };
    const baseColumns = [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        meta: {
          clickable: false,
        },
      },
      columnHelper.accessor(
        (row) => {
          let parsedData;
          try {
            parsedData = JSON.parse(row.data);
            if (typeof parsedData === "string") {
              parsedData = JSON.parse(parsedData);
            }
            return parsedData.name;
          } catch (e) {
            return "";
          }
        },
        {
          id: "name",
          cell: (info) => info.getValue(),
          header: t("translation:name"),
          meta: {
            clickable: false,
          },
        },
      ),
      columnHelper.accessor("orderStatus", {
        id: "orderStatus",
        cell: (info) => info.getValue(),
        header: t("translation:status"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        // eslint-disable-next-line react/jsx-no-undef
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:creationDate"),
        meta: {
          clickable: false,
        },
      }),
      columnHelper.accessor("id", {
        id: "actions",
        header: "",
        cell: (info) => (
          <button
            onClick={handleClick(info.getValue())}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
          >
            <ArrowCircleRight color="green" />
          </button>
        ),
        meta: {
          clickable: false,
        },
      }),
    ];

    return baseColumns;
  }, [columnHelper, integration.appId, integration.id, navigate, t]);

  const isRowSelectable = useCallback(
    (row) => row.original?.orderStatus !== "Sent to Power Pick",
    [],
  );

  return (
    <Row gutter={20}>
      <Col span={24}>
        <Row gutter={20}>
          <Col span={24}>
            <InnerControls
              handleSearch={handleSearch}
              setStatusIds={handleStatusFilterChange}
              updateStatusIds={updateStatusIds}
              deleteOrders={deleteOrders}
            />
          </Col>
        </Row>
        <Table
          rows={orders}
          tableColumns={columns}
          length={ordersCount}
          sort={sort}
          setSort={setSort}
          loading={isFetching}
          enableRowSelection={isRowSelectable}
          setSelectedRows={setSelectedOrders}
          emptyText={t("translation:no_orders_found")}
          paginationEnabled={{
            currentPage: page,
            pageSize,
            setPage,
            setPageSize,
          }}
          clearSelectedRows={clearSelected}
        />
      </Col>
    </Row>
  );
}

import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import { useDisclosure } from "@heroui/react";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";

export default function ClearLogs({ clearLogs }: { clearLogs: () => void }) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleClearLogs = useCallback(() => {
    clearLogs();
    onOpenChange(); // Close the modal after clearing logs
  }, [clearLogs, onOpenChange]);
  return (
    <>
      <HeroButton
        size="sm"
        variant="bordered"
        color="primary"
        onClick={onOpen}
        className="border-[#a12121] text-[#a12121] bg-white"
      >
        {t("translation:clear")} {t("translation:logs")}
      </HeroButton>
      <HeroModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:confirm_delete_logs"),
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:cancel")}
              </HeroButton>
              <HeroButton size="sm" color="red" onClick={handleClearLogs}>
                {t("translation:delete")}
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </>
  );
}

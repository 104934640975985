import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { toast } from "sonner";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { integrationService, connectionService } from "/app/src/services";
import Headers from "./headers";
import { Connection, Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import ConnectionSettings from "./connectionSettings";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import HeroSwitch from "/app/src/components/HeroUi/Switch";
import Box from "/app/src/components/generic/components/box";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

/**
 * Format the form value keys to match the API Model
 */
function formatForm(values: IntegrationFormValues): Integration {
  return {
    name: values.name,
    number: values.number,
    type: values.type,
  };
}

interface IntegrationFormValues {
  name: string | undefined;
  type: string | undefined;
  number: string | number | undefined;
}

/**
 * Component to display the settings for a single Data Pull or Data Push Integration
 */
export default function Settings({
  integration,
  children = null,
}: {
  integration: Integration;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  /**
   * Update the integration
   */
  const updateIntegrationHandler = useCallback(
    async (
      values: IntegrationFormValues,
      actions: FormikHelpers<IntegrationFormValues>,
    ) => {
      if (integration?.id) {
        await updateIntegration({
          id: integration.id,
          ...formatForm(values),
        }).then(() => {
          actions.resetForm();
        });
      }
    },
    [integration.id, updateIntegration],
  );

  const { data: connections } = useQuery({
    queryKey: ["connections", "REST"],
    queryFn: () => {
      return connectionService.getAll(buildParams({ type: "REST" }));
    },
    initialData: { connections: [] },
    select: (data = { connections: [] }) => {
      return data.connections;
    },
  });

  const { data: integrationConnectionStatus, isFetching } = useQuery({
    queryKey: ["connectionStatus", integration.connectionId],
    queryFn: () => {
      return connectionService.getSingle(integration.connectionId);
    },
    initialData: { connection: {} },
    select: (data: { connection: Connection }) => {
      return data.connection?.status;
    },
  });

  const toggleIntegrationStatus = useCallback(
    (value: boolean) => {
      if (integration?.status === 0 || integration?.status === null) {
        if (integrationConnectionStatus === 0) {
          toast.error(t("translation:connection_invalid"));
        } else {
          updateIntegration({ id: integration.id, status: Number(value) });
        }
      } else {
        updateIntegration({ id: integration.id, status: Number(value) });
      }
    },
    [
      integration.id,
      integration.status,
      integrationConnectionStatus,
      t,
      updateIntegration,
    ],
  );

  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={t("translation:integration_name")}
              hasFeedback={false}
            >
              <Input
                name="name"
                placeholder={t("translation:enter_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="number" label={t("translation:format")}>
              <Select
                name="number"
                size="large"
                placeholder={t("translation:select_format")}
              >
                {["JSON", "XML"].map((t) => (
                  <Select.Option value={t} key={t}>
                    {t}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="type" label={t("translation:type")}>
              <Select
                name="type"
                size="large"
                placeholder={t("translation:select_type")}
              >
                <Select.Option value="automatic">
                  {t("translation:automatic")}
                </Select.Option>
                <Select.Option value="manual">
                  <>
                    <span>{t("translation:approval_required")}</span>
                    <IconToolTip
                      content={t("translation:manual_data_push_tip")}
                      className="ml-1 inline-block"
                    />
                  </>
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:update")} {t("translation:integration")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  return (
    <>
      <Box>
        <Row>
          <Col span={23}>
            <h1>{t("translation:general")}</h1>
          </Col>
          <Col span={1}>
            <h4>{t("translation:active")}:</h4>
            <HeroSwitch
              isSelected={integration?.status === 1}
              onValueChange={toggleIntegrationStatus}
              isDisabled={isFetching}
            />
          </Col>
        </Row>
        <Formik
          component={updateIntegrationForm}
          initialValues={{
            name: integration.name,
            number: integration.number,
            type: integration.type ? integration.type : "automatic",
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          enableReinitialize
          onSubmit={updateIntegrationHandler}
        />
      </Box>
      <ConnectionSettings
        integration={integration}
        connections={connections}
        method
      />
      <Headers integration={integration} />
      {children}
    </>
  );
}

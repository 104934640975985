import { iField, iOrder as iOrderType } from "/app/src/models";
import { iFieldService, iOrderService, statusService } from "/app/src/services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { Row, Col, InputNumber } from "antd";
import { Form, Input, Select } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Box from "/app/src/components/generic/components/box";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { buildParams, getDateFormat } from "/app/src/helpers";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { orderBuilderFields } from "/app/src/constants/orderBuilderFields";
import NewDetail from "./newDetail";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroDivider from "/app/src/components/HeroUi/Divider";
import { useNavigate } from "react-router-dom";
import { LocationType } from "./locationType";

interface FormValues {
  name?: string;
  directionType?: string;
  Info1?: string;
  Info2?: string;
  Info3?: string;
  Info4?: string;
  Info5?: string;
  priority?: string;
  warehouseName?: string;
  internalName?: string;
  statusId?: number;
  carriers?: string[];
  carriers_type?: string;
  locations?: string[];
  locations_type?: string;
  storageUnits?: string[];
  storageUnits_type?: string;
  zones?: string[];
  zones_type?: string;
}

/**
 * Formats the input values into an array of objects based on the specified type.
 *
 * @param {string} [type] - The type to use as the key in the formatted array objects.
 * @param {string|string[]} [values] - The value(s) to be formatted into the array.
 * @returns {Object[]|undefined} An array of objects with the given type as the key or undefined if input is invalid.
 */
function formatArrayField(type?: string, values?: string | string[]) {
  if (
    !type ||
    !values ||
    values === "" ||
    (Array.isArray(values) && values.length === 0)
  ) {
    return undefined;
  }
  return Array.isArray(values)
    ? values.map((val) => ({ [type]: val }))
    : [{ [type]: values }];
}

/**
 * Formats the form values to be sent to the backend API
 */
function formatForm(values: FormValues) {
  const ret = {
    name: values.name,
    directionType: values.directionType,
    Info1: values.Info1,
    Info2: values.Info2,
    Info3: values.Info3,
    Info4: values.Info4,
    Info5: values.Info5,
    priority: values.priority,
    warehouseName: values.warehouseName,
    carriers: formatArrayField(values.carriers_type, values.carriers),
    locations: formatArrayField(values.locations_type, values.locations),
    storageUnits: formatArrayField(
      values.storageUnits_type,
      values.storageUnits,
    ),
    zones: formatArrayField(values.zones_type, values.zones),
  };

  //json stringify the object
  return { data: JSON.stringify(ret), statusId: values.statusId };
}

/**
 * Displays the order details of an order created through the Order Builder App
 */
export default function OrderDetails({ iOrder }: { iOrder: iOrderType }) {
  const { t } = useTranslation();
  const { timeZone } = useContext(TimeZoneContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const goToOrder = useCallback(() => {
    navigate(`/explorer/orders/${iOrder.powerpickId}`);
  }, [iOrder.powerpickId, navigate]);
  const { data: orderFields } = useQuery({
    queryKey: ["orderFields", iOrder.integrationId],
    queryFn: () => {
      return iFieldService.getAll(
        buildParams({ integrationId: iOrder.integrationId, type: "order" }),
      );
    },
    initialData: { fields: [] },
    select: (data: { fields: iField[] }) => {
      return data.fields;
    },
  });
  const { data: statuses } = useQuery({
    queryKey: ["statuses"],
    queryFn: () => statusService.getAll(),
    select: (data) => data.statuses,
    initialData: { statuses: [] },
  });
  const saveOrder = useCallback(
    async (values: FormValues) => {
      await iOrderService
        .updateSingle(iOrder.id, formatForm(values))
        .then((response) => {
          queryClient.setQueryData(["order", iOrder.id], response);
        });
    },
    [iOrder.id, queryClient],
  );
  const deleteOrderField = useCallback(
    (fieldValue: string) => async () => {
      // Remove the field from the loadedData using Object.assign
      // because using delete operator on dynamic object is dangerous
      const { [fieldValue]: _, ...newLoadedData } = iOrder.loadedData;
      const orderData = JSON.stringify(newLoadedData);
      return await iOrderService
        .updateSingle(iOrder.id, { data: orderData })
        .then((response) => {
          queryClient.setQueryData(["order", iOrder.id], response);
        });
    },
    [iOrder.id, iOrder.loadedData, queryClient],
  );

  /**
   * Extract location information from the given data object based on the specified key.
   * @param {string} key - The key to extract location information for.
   * @param {object} data - The data object containing location information.
   * @returns {object} An object containing the location type key and its associated values.
   */
  const extractLocationInfo = (key: string, data: object) => {
    //check if key exists if it doesnt return an empty object
    // if it does exist get the value which is an array of objects
    // then get the first key of the first object and that will be the type inital value
    let accessor = "";
    let locationValues = [];

    //check if key exists if it doesnt return an empty object if it exists get the value which is an array of objects
    if (!data[key]) {
      return {};
    }

    //the location values should be an array of the values no keys - have to check for backwards compatibility
    const locationArray = Array.isArray(data[key])
      ? data[key]
      : [{ name: data[key] }];
    locationValues = locationArray.map((location) => {
      return Object.values(location)[0];
    });
    //get the first key of the first object and that will be the type inital value
    if (locationArray.length > 0) {
      accessor = Object.keys(locationArray[0])[0];
    } else {
      accessor = "id";
    }

    // then get the values of all of the objects and add them to an array
    // return an object with the key and the array of values
    return { key: accessor, values: locationValues };
  };

  /**
   * Get the initial location data for the order.
   */
  const getInitialLocationData = useCallback(() => {
    const locationKeys = ["carriers", "locations", "storageUnits", "zones"];
    const locationInitialData = {};
    locationKeys.forEach((key) => {
      const extractedData = extractLocationInfo(key, iOrder.loadedData);

      //check for empty object return from extractLocationInfo, if empty continue loop
      //if its not empty concatenate the '${key}_type' and the value will be the first key of the extracted location info

      if (extractedData.key) {
        locationInitialData[`${key}_type`] = extractedData.key;
        locationInitialData[key] = extractedData.values;
      }
    });
    return locationInitialData;
  }, [iOrder.loadedData]);

  /**
   * Render the input fields. If the field is quantity, render an InputNumber
   * @param field
   */
  const renderInput = useCallback(
    (field, disabled) => {
      const commonProps = {
        name: field.value,
        label: field.label,
      };
      const lineField = orderFields.find((f) => f.name === field.value);
      const disabledFields = ["carriers", "locations", "storageUnits", "zones"];

      if (disabledFields.includes(field.value)) return null;

      return (
        <>
          <Col span={7} key={field.value}>
            <Form.Item {...commonProps}>
              {field.value === "quantity" ? (
                <InputNumber
                  disabled={lineField?.viewOnly || disabled}
                  name={field.value}
                  min={1}
                  size="large"
                />
              ) : (
                <Input
                  disabled={lineField?.viewOnly || disabled}
                  name={field.value}
                  suffix
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            {field.value !== "name" && (
              <HeroButton
                onClick={deleteOrderField(field.value)}
                color="default"
                size="md"
                variant="bordered"
                isIconOnly
                isDisabled={
                  iOrder.orderSent || lineField?.viewOnly || lineField?.required
                }
                className="border-error-default bg-white mt-[30px]"
              >
                <IconBuilder size={18} color="#e00000" icon="Delete" />
              </HeroButton>
            )}
          </Col>
        </>
      );
    },
    [orderFields, iOrder, deleteOrderField],
  );
  const newOrderForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <FormikDisabler disabled={iOrder.orderSent} />
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <div className="flex items-center space-x-2">
                <h2>{t("translation:order_details")}</h2>
                {iOrder?.powerpickId && (
                  <IconBuilder
                    icon="LinkExternal"
                    size={18}
                    onClick={goToOrder}
                  />
                )}
              </div>
            </Col>
            <Col span={6} offset={12}>
              <Form.Item name="statusId" hasFeedback={false}>
                <Select
                  name="statusId"
                  size="large"
                  disabled={iOrder?.orderSent}
                >
                  {statuses
                    .filter((status) => {
                      if (
                        status.name === "Sent to Power Pick" &&
                        iOrder.statusId !== status.id
                      ) {
                        return false;
                      }
                      return !(
                        status.name === "Error" && iOrder.statusId !== status.id
                      );
                    })
                    .map((status) => (
                      <Select.Option key={status.id} value={status.id}>
                        {status.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <DisabledSubmitButton
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:save")}
              </DisabledSubmitButton>
            </Col>
          </Row>
          <HeroDivider className="my-3" />
          <Row justify="start" gutter={16}>
            <Col span={7}>
              <Form.Item
                name="internalName"
                label={t("translation:internal_name")}
              >
                <Input disabled name={"internalName"} suffix size="large" />
              </Form.Item>
            </Col>
            <Col span={1} />
            {orderBuilderFields
              .filter((field) => field.value in iOrder.loadedData)
              .map((field) => renderInput(field, iOrder?.orderSent))}
          </Row>
          <LocationType
            iOrder={iOrder}
            orderFields={orderFields}
            locationInitialData={getInitialLocationData()}
          />
        </Form>
      ),
      [
        getInitialLocationData,
        goToOrder,
        iOrder,
        orderFields,
        renderInput,
        statuses,
        t,
      ],
    );

  const getInitValues = useCallback(() => {
    return {
      internalName: iOrder?.name,
      creationDate: getDateFormat(
        iOrder?.creationDate,
        "YYYY-MM-DDTHH:mm:ss",
        timeZone,
      ),
      statusId: iOrder.statusId,
      ...iOrder.loadedData,
      ...getInitialLocationData(),
    };
  }, [
    getInitialLocationData,
    iOrder?.creationDate,
    iOrder.loadedData,
    iOrder?.name,
    iOrder.statusId,
    timeZone,
  ]);
  return (
    <Box>
      <Formik
        enableReinitialize
        component={newOrderForm}
        initialValues={getInitValues()}
        onSubmit={saveOrder}
      />
      {iOrder?.orderSent !== undefined && !iOrder?.orderSent && (
        <NewDetail
          iOrder={iOrder}
          availableFields={orderBuilderFields.filter((field) => {
            return !Object.keys(iOrder.loadedData).includes(field.value);
          })}
        />
      )}
    </Box>
  );
}

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroSelect from "/app/src/components/HeroUi/Select";
import { InputNumber, Row, Col } from "antd";

interface FormValues {
  value: string | undefined;
}

/**
 *
 * @returns DynamicButtons component
 */
export default function DynamicButtons({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [period, setPeriod] = useState<string>("last");
  const [quantity, setQuantity] = useState<number>(1);
  const [frequency, setFrequency] = useState<string>("day");

  const updateQuantity = useCallback(
    (value: number) => {
      setQuantity(value);
    },
    [setQuantity],
  );

  const updatePeriod = useCallback(
    (e) => {
      setPeriod(e.target.value);
    },
    [setPeriod],
  );

  const updateFrequency = useCallback(
    (e) => {
      setFrequency(e.target.value);
    },
    [setFrequency],
  );

  const addField = useCallback(() => {
    const currentValue = values.value ?? "";
    setFieldValue("value", `${currentValue}{${period}${quantity}${frequency}}`);
  }, [values.value, period, quantity, frequency, setFieldValue]);

  return (
    <div className="mt-2">
      <Row gutter={16}>
        <Col span={7}>
          <HeroSelect
            value={period}
            defaultSelectedKeys={["last"]}
            onChange={updatePeriod}
            items={[
              { key: "last", label: "Last", value: "last" },
              { key: "next", label: "Next", value: "next" },
            ]}
            ariaLabel="Select period"
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={1}
            max={100}
            value={quantity}
            size="large"
            onChange={updateQuantity}
          />
        </Col>
        <Col span={7}>
          <HeroSelect
            value={frequency}
            defaultSelectedKeys={["day"]}
            onChange={updateFrequency}
            items={[
              { key: "day", label: "Day(s)", value: "day" },
              { key: "week", label: "Week(s)", value: "week" },
              { key: "month", label: "Month(s)", value: "month" },
            ]}
            ariaLabel="Select period"
          />
        </Col>
        <Col span={6}>
          <HeroButton
            isDisabled={disabled}
            onClick={addField}
            size="md"
            fullWidth
          >
            {t("translation:add")}
          </HeroButton>
        </Col>
      </Row>
    </div>
  );
}

import React from "react";
import { Accordion } from "@heroui/react";

interface HeroAccordionProps {
  children?: any;
  variant?: "light" | "shadow" | "bordered" | "splitted";
  selectionMode?: "none" | "single" | "multiple";
  selectionBehavior?: "toggle" | "replace";
  isCompact?: boolean;
  isDisabled?: boolean;
  showDivider?: boolean;
  hideIndicator?: boolean;
  disableAnimation?: boolean;
  disableIndicatorAnimation?: boolean;
  disallowEmptySelection?: boolean;
  keepContentMounted?: boolean;
  fullWidth?: boolean;
  motionProps?: { [key: string]: any };
  itemClasses?: { [key: string]: string };
  selectedKeys?: string[];
  defaultSelectedKeys?: string[];
  disabledKeys?: string[];
  onSelectionChange?: (keys) => any;
}

/**
 * HeroAccordion component renders an accordion with various customizable properties.
 *
 * @param {HeroAccordionProps} props - The properties for the HeroAccordion component.
 * @returns {JSX.Element} The rendered Accordion component.
 */
const HeroAccordion: React.FC<HeroAccordionProps> = ({
  children,
  variant = "light",
  selectionMode = "none",
  selectionBehavior = "toggle",
  isCompact,
  isDisabled,
  showDivider = true,
  hideIndicator,
  disableAnimation,
  disableIndicatorAnimation,
  disallowEmptySelection,
  keepContentMounted = false,
  fullWidth = true,
  motionProps,
  itemClasses,
  selectedKeys,
  defaultSelectedKeys,
  disabledKeys,
  onSelectionChange,
}) => {
  return (
    <Accordion
      variant={variant}
      selectionMode={selectionMode}
      selectionBehavior={selectionBehavior}
      isCompact={isCompact}
      isDisabled={isDisabled}
      showDivider={showDivider}
      hideIndicator={hideIndicator}
      disableAnimation={disableAnimation}
      disableIndicatorAnimation={disableIndicatorAnimation}
      disallowEmptySelection={disallowEmptySelection}
      keepContentMounted={keepContentMounted}
      fullWidth={fullWidth}
      motionProps={motionProps}
      itemClasses={itemClasses}
      selectedKeys={selectedKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      disabledKeys={disabledKeys}
      onSelectionChange={onSelectionChange}
    >
      {children}
    </Accordion>
  );
};

export default HeroAccordion;

import { extendVariants, Textarea } from "@heroui/react";
import getDisabledStyle from "./helpers/disabledState";

export const CustomTextarea = extendVariants(
  Textarea,
  getDisabledStyle("base"),
);

interface HeroTextareaProps {
  children?: React.ReactNode;
  minRows?: number;
  maxRows?: number;
  cacheMeasurements?: boolean;
  variant?: "flat" | "bordered" | "faded" | "underlined";
  color?: "default" | "primary" | "success" | "warning" | "danger";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  label?: React.ReactNode;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  startContent?: React.ReactNode;
  endContent?: React.ReactNode;
  description?: React.ReactNode;
  errorMessage?: string;
  validate?: (value: string) => true | null | undefined;
  labelPlacement?: "inside" | "outside" | "outside-left";
  fullWidth?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  disableAutosize?: boolean;
  disableAnimation?: boolean;
  classNames?: { [key: string]: string };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string) => void;
  onClear?: () => void;
  onHeightChange?: (height: number, meta: { rowHeight: number }) => void;
  className?: string;
}

/**
 *
 * @param param0 children, minRows, maxRows, cacheMeasurements, variant, color, size, radius, label, value, defaultValue, placeholder, startContent, endContent, description, errorMessage, validate, validationBehavior, labelPlacement, fullWidth, isRequired, isReadOnly, isDisabled, isInvalid, validationState, disableAutosize, disableAnimation, classNames, onChange, onValueChange, onClear, onHeightChange
 * @returns NextUI Textarea
 */
const HeroTextarea: React.FC<HeroTextareaProps> = ({
  children,
  minRows = 3,
  maxRows = 8,
  cacheMeasurements = false,
  variant = "flat",
  color = "default",
  size = "md",
  radius,
  label,
  value,
  defaultValue,
  placeholder,
  startContent,
  endContent,
  description,
  errorMessage,
  validate,
  labelPlacement = "inside",
  fullWidth = true,
  isRequired = false,
  isReadOnly,
  isDisabled = false,
  isInvalid = false,
  disableAutosize = false,
  disableAnimation = false,
  classNames,
  onChange,
  onValueChange,
  onClear,
  onHeightChange,
  className,
}) => {
  return (
    <CustomTextarea
      minRows={minRows}
      maxRows={maxRows}
      cacheMeasurements={cacheMeasurements}
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      label={label}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      startContent={startContent}
      endContent={endContent}
      description={description}
      errorMessage={errorMessage}
      validate={validate}
      labelPlacement={labelPlacement}
      fullWidth={fullWidth}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      disableAutosize={disableAutosize}
      disableAnimation={disableAnimation}
      classNames={classNames}
      onChange={onChange}
      onValueChange={onValueChange}
      onClear={onClear}
      onHeightChange={onHeightChange}
      className={className}
    >
      {children}
    </CustomTextarea>
  );
};

export default HeroTextarea;

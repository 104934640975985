import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@heroui/react";

/**
 * ModalBuilder component to create a modal with optional warning icon, header, body, and footer.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} [props.warning] - Whether to show a warning icon.
 * @param {string} [props.modalHeader] - The header text of the modal.
 * @param {string} [props.modalBody] - The body content of the modal.
 * @param {React.ReactNode} [props.modalFooter] - The footer content of the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
export default function ModalBuilder({
  warning,
  modalHeader,
  modalBody,
  modalFooter,
}: {
  warning?: boolean;
  modalHeader?: string;
  modalBody?: string;
  modalFooter?: React.ReactNode;
}) {
  return (
    <ModalContent>
      <ModalHeader>
        <div className="flex items-center">
          {warning && (
            <IconBuilder
              icon="ExclamationCircle"
              color="#faad14"
              size={32}
              className="pr-2"
            />
          )}{" "}
          {modalHeader}
        </div>
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>{modalFooter}</ModalFooter>
    </ModalContent>
  );
}

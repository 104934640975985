/**
 * Returns the disabled style for the specified target.
 * @param {("base" | "input" | "wrapper")} target - The target element to apply the disabled style.
 * @returns {object} The style object with the disabled variant.
 */
export default function getDisabledStyle(target: "base" | "input" | "wrapper") {
  return {
    variants: {
      isDisabled: {
        true: {
          [target]: "opacity-50 cursor-not-allowed pointer-events-auto",
        } as { [key in "base" | "input" | "wrapper"]: string },
      },
    },
  };
}

import React, { useCallback, useState } from "react";
import { toast } from "sonner";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import HeroButton from "/app/src/components/HeroUi/Button";
import { useNavigate } from "react-router-dom";

/**
 * Function to validate the file name
 * @param fileName name of the file
 * @returns boolean
 */
function validateFileName(fileName: string) {
  return fileName.includes("itempath") && fileName.includes(".json");
}

/**
 * Button to import an integration template
 */
export default function ImportTemplate({ appId }: { appId: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeImport, setActiveImport] = useState(false);

  /**
   * Function to navigate to the integration page
   */
  const goToIntegration = useCallback(
    (integrationId: number) => {
      navigate(`/apps/${appId}/integrations/${integrationId}`);
    },
    [navigate, appId],
  );
  const toggleActiveImport = useCallback(() => {
    setActiveImport(!activeImport);
  }, [activeImport]);

  /**
   * Handler that reads the file and calls API to import the integration
   * Then invalidates the cache and navigates to the new integration
   * @param e click event
   */
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileReader = new FileReader();
      if (e.currentTarget?.files && e.currentTarget.files.length > 0) {
        if (validateFileName(e.currentTarget.files[0].name)) {
          fileReader.readAsText(e.currentTarget.files[0], "UTF-8");
          fileReader.onload = (e) => {
            if (e.target?.result) {
              const data = JSON.parse(e.target.result as string);
              integrationService
                .importSingle(data, buildParams({ import: "True" }))
                .then((response) => {
                  goToIntegration(response.integration.id);
                });
            }
          };
        } else {
          toast.error(t("translation:incorrect_file_format"));
        }
      }
    },
    [goToIntegration, t],
  );
  return (
    <div className="flex items-center space-x-4">
      {activeImport ? (
        <>
          <input type="file" onChange={handleChange} />
          <HeroButton
            size="sm"
            variant="bordered"
            color="default"
            onClick={toggleActiveImport}
            className="hover:border-primary-default hover:text-primary-default bg-white"
          >
            {t("translation:cancel")}
          </HeroButton>
        </>
      ) : (
        <HeroButton
          size="sm"
          variant="bordered"
          color="primary"
          onClick={toggleActiveImport}
          className="hover:border-primary-default hover:text-primary-default bg-white"
        >
          {t("translation:import")} {t("translation:template")}
        </HeroButton>
      )}
    </div>
  );
}

import React, { useState, useCallback } from "react";
import { useFormikContext } from "formik";
import { TwitterPicker, ColorResult } from "react-color";

/**
 * A colour picker component. Must be used within a Formik form.
 * @returns the colour picker
 */
export default function ColorPicker() {
  const [colourOpen, setColourOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<{ value: string }>();

  /**
   * Toggle the colour picker open or closed
   */
  const toggleColour = useCallback(() => {
    setColourOpen((prev) => !prev);
  }, []);

  const handleColorChange = useCallback(
    (color: ColorResult) => {
      setFieldValue("value", color.hex);
      setColourOpen(false);
    },
    [setFieldValue],
  );

  return (
    <div className="relative mt-[3px]">
      <button
        type="button"
        className="h-6 w-6 block rounded-[3px] border border-[#ebeef2]"
        onClick={toggleColour}
        style={{ background: values.value }}
      />
      {colourOpen && (
        <div className="absolute -left-[244px] top-8 z-10">
          <TwitterPicker
            triangle="top-right"
            color={values.value}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </div>
  );
}

import { object, string, number } from "yup";
import i18next from "/app/src/locales/config";

export const snapshotSchema = object().shape({
  name: string().required(i18next.t("translation:required")),
  type: string().required(i18next.t("translation:required")),

  reportColumnId: number().when("type", {
    is: (type) => type !== "count",
    then: (schema) => schema.required(i18next.t("translation:required")),
    otherwise: (schema) => schema.nullable(),
  }),
});

import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, InputNumber } from "formik-antd";
import { useTranslation } from "react-i18next";
import { App as AppType } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";
import useSetting from "../setting";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  pollingFrequency: number;
}

/**
 * DataPull component is responsible for rendering a form that allows users to update the polling frequency setting for a given app.
 *
 * @param {Object} props - The properties object.
 * @param {AppType} props.app - The application object containing the app details.
 *
 * @returns {JSX.Element} The rendered DataPull component.
 *
 * @component
 *
 * @example
 * // Usage example:
 * <DataPull app={app} />
 *
 * @remarks
 * This component uses Formik for form management and validation, and it leverages the useSetting hook to manage the polling frequency setting.
 * The form includes an input number field for the polling frequency and a submit button to save the changes.
 *
 * @requires useTranslation - A hook from the react-i18next library for translation.
 * @requires useSetting - A custom hook for managing settings.
 * @requires Formik - A library for building forms in React.
 * @requires FormikProps - Type definitions from Formik.
 * @requires Form - Ant Design's Form component.
 * @requires InputNumber - Ant Design's InputNumber component.
 * @requires SubmitButton - Ant Design's SubmitButton component.
 * @requires Row - Ant Design's Row component.
 * @requires Col - Ant Design's Col component.
 * @requires IconToolTip - A custom component for displaying tooltips.
 * @requires simpleSchemaBuilder - A utility function for building validation schemas.
 */
export default function DataPull({ app }: { app: AppType }) {
  const { t } = useTranslation();
  const {
    settingValue: pollingFrequency,
    createUpdateSetting: createUpdatePollingFrequency,
  } = useSetting({
    appId: app.id,
    type: "pollingFrequency",
  });

  /**
   * Handles the form submission to update the polling frequency.
   *
   * @param {FormValues} values - The form values containing the polling frequency.
   * @returns {Promise<void>} A promise that resolves when the polling frequency is updated.
   */
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      return await createUpdatePollingFrequency({
        value: values.pollingFrequency,
        appId: app.id,
        type: "pollingFrequency",
      });
    },
    [app.id, createUpdatePollingFrequency],
  );

  const hueSettingsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <div className="flex">
            <h3>
              {app.name} - {t("translation:polling_frequency")}
            </h3>
            <IconToolTip
              content={t("translation:polling_frequency_description")}
              className="ml-1"
            />
          </div>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item name="pollingFrequency">
                <InputNumber
                  name="pollingFrequency"
                  size="large"
                  min={10}
                  max={300}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton type="primary" size="large" block disabled={!dirty}>
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [app.name, t],
    );
  return (
    <div className="appSettings">
      <Formik
        component={hueSettingsForm}
        initialValues={{
          pollingFrequency: pollingFrequency || 10,
        }}
        enableReinitialize
        validationSchema={simpleSchemaBuilder([
          { name: "pollingFrequency", type: "number", required: true },
        ])}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

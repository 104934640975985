import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  useAuthState,
  updateUserContext,
  useAuthDispatch,
} from "/app/src/contexts/authentication";
import { useTranslation } from "react-i18next";
import { viewService, userService } from "/app/src/services";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { Col, Row, Select, Checkbox } from "antd";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { View } from "/app/src/models";
import Widgets from "./Widgets";
import NewView from "./NewView";
import { useToggle } from "/app/src/hooks/";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useContextForPermission } from "/app/src/hooks/usePermission";
import HeroButton from "/app/src/components/HeroUi/Button";
import HeroModal from "/app/src/components/HeroUi/Modal";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useDisclosure } from "@heroui/react";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  name: string | undefined;
}

/**
 * Component for displaying the Dashboard page
 */
export default function Dashboard() {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [editingView, setEditingView] = useState(false);
  const [editingTitle, toggleEditingTitle] = useToggle(false);
  const [newToggled, toggleNew] = useToggle(false);
  const dispatch = useAuthDispatch();
  const { canEdit } = useContextForPermission("DASH");
  const viewQuery = useQuery({
    queryKey: ["view", user.id],
    queryFn: () =>
      viewService.getSingle(user.viewId).then((data) => {
        if (data.view) {
          return data;
        } else {
          return { view: {} };
        }
      }),
    initialData: { view: {} },
  });

  const userViewsQuery = useQuery({
    queryKey: ["userViews", user.id],
    queryFn: () => viewService.getAll(buildParams({ userId: user.id })),
    initialData: { views: [] },
  });
  const sharedViewsQuery = useQuery({
    queryKey: ["sharedViews", user.id],
    queryFn: () =>
      viewService.getAll(
        buildParams({ isPrivate: false, userId: `[not]${user.id}` }),
      ),
    initialData: { views: [] },
  });
  const { mutateAsync: updateUserView } = useMutation({
    mutationFn: (viewId: number) =>
      userService.updateSingle(user.id, { viewId }).then(handlePromiseError),
    onSuccess: (data) => {
      updateUserContext(dispatch, data.user);
    },
  });

  const { mutateAsync: updateView } = useMutation({
    mutationFn: (values: View) =>
      viewService.updateSingle(values.id, values).then(handlePromiseError),
    onSuccess: (data) => {
      queryClient.setQueryData(["view", user.id], data);
      queryClient.setQueryData(
        ["userViews", user.id],
        (oldData: { views: View[] }) => {
          return {
            views: oldData.views.map((v: View) => {
              if (v.id === data.view.id) {
                return data.view;
              } else {
                return v;
              }
            }),
          };
        },
      );
    },
  });

  const { mutateAsync: createView } = useMutation({
    mutationFn: (values: View) =>
      viewService
        .createSingle({ ...values, userId: user.id })
        .then(handlePromiseError),
    onSuccess: (data) => {
      updateUserView(data.view.id);
      queryClient.setQueryData(["view", user.id], data);
      queryClient.setQueryData(
        ["userViews", user.id],
        (oldData: { views: View[] }) => {
          return { views: [...oldData.views, data.view] };
        },
      );
    },
  });

  const { mutateAsync: deleteView } = useMutation({
    mutationFn: (viewId: number) => viewService.deleteSingle(viewId),
    onSuccess: () => {
      queryClient.setQueryData(
        ["userViews", user.id],
        (oldData: { views: View[] }) => {
          return {
            views: oldData.views.filter(
              (v: View) => v.id !== viewQuery.data.view.id,
            ),
          };
        },
      );
      queryClient.setQueryData(["view", user.id], { view: {} });
    },
  });

  const selectView = useCallback(
    (selectedViewId: number) => {
      //ensure editing is closed
      if (editingTitle) {
        toggleEditingTitle();
      }
      setEditingView(false);

      //look in both useQueries for the selected view
      const foundView = userViewsQuery.data.views.find((v) => {
        return v.id === selectedViewId;
      });
      if (foundView) {
        queryClient.setQueryData(["view", user.id], {
          view: foundView,
        });
      } else {
        const foundSharedView = sharedViewsQuery.data.views.find((v) => {
          return v.id === selectedViewId;
        });
        if (foundSharedView) {
          queryClient.setQueryData(["view", user.id], {
            view: foundSharedView,
          });
        }
      }
      //update user viewId here
      updateUserView(selectedViewId);
    },
    [
      editingTitle,
      queryClient,
      sharedViewsQuery.data.views,
      toggleEditingTitle,
      updateUserView,
      user.id,
      userViewsQuery.data.views,
    ],
  );

  /**
   * Function for creating a new view and then closing the new view form
   * @param values the values from the create new view form
   */
  const createNewView = useCallback(
    (values: View & { viewId?: number }) => {
      createView(values).then(() => {
        toggleNew();
      });
    },
    [createView, toggleNew],
  );

  /**
   * Function for toggling the editing view state
   */
  const toggleIsEditing = useCallback(() => {
    setEditingView(!editingView);
  }, [editingView]);

  /**
   * Function for toggling the private state of a view
   * @param e the checkbox change event
   */
  const handlePrivateToggle = useCallback(
    (e: CheckboxChangeEvent) => {
      updateView({
        id: viewQuery.data.view.id,
        isPrivate: !e.target.checked,
      });
    },
    [updateView, viewQuery.data.view.id],
  );

  const handleSubmit = useCallback(
    (values, actions) => {
      updateView({
        id: viewQuery.data.view?.id,
        name: values.name,
      })
        .then(() => {
          actions.resetForm();
          toggleEditingTitle();
        })
        .catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    [updateView, viewQuery.data.view, toggleEditingTitle],
  );
  /**
   * Formik form for editing the title of a view
   */
  const editTitleForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ isSubmitting }) => (
        <Form>
          <Row>
            <Col span={20}>
              <Form.Item name="name" hasFeedback={false}>
                <Input type="text" name="name" className="titleInput" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                className="saveButton"
                type="primary"
                size="large"
                block
                disabled={isSubmitting}
              >
                <img src={saveIcon} alt="Save" />
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [],
    );
  const handleEditClick = useCallback(() => {
    toggleEditingTitle();
  }, [toggleEditingTitle]);

  const deleteDashboardView = useCallback(() => {
    deleteView(viewQuery.data.view.id);
    onOpenChange();
  }, [deleteView, onOpenChange, viewQuery.data.view.id]);

  return (
    <div className="dashboard">
      <Helmet>
        <title>{t("translation:dashboard")} - ItemPath</title>
      </Helmet>
      <Row gutter={15} justify="end">
        {editingTitle ? (
          <Col flex="auto">
            <Formik
              component={editTitleForm}
              initialValues={{
                name: viewQuery.data.view?.name,
              }}
              validationSchema={simpleSchemaBuilder([
                { name: "name", type: "string", required: true },
              ])}
              enableReinitialize
              onSubmit={handleSubmit}
            />
          </Col>
        ) : (
          <>
            <Col
              flex={
                viewQuery.data.view?.userId !== user?.id || !canEdit
                  ? "auto"
                  : ""
              }
              className="flex items-center"
            >
              <div className="noEdit">
                <h2 className="mb-0">
                  {viewQuery.data.view?.name
                    ? `${t("translation:dashboard")} - ${
                        viewQuery.data.view?.name
                      }`
                    : t("translation:dashboard")}
                </h2>
              </div>
            </Col>
            {viewQuery.data.view?.userId === user?.id && canEdit && (
              <Col className="h-[24px] w-[24px]" flex="auto">
                <EditButton onClick={handleEditClick} />
              </Col>
            )}
          </>
        )}
        <Col>
          <Select
            style={{ width: 180 }}
            onChange={selectView}
            size="large"
            placeholder={t("translation:select_view")}
            value={viewQuery.data.view?.id}
          >
            <Select.OptGroup label={t("translation:your_views")}>
              {userViewsQuery.data.views.map((v) => (
                <Select.Option value={v.id} key={v.id}>
                  {v.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
            <Select.OptGroup label={t("translation:shared_views")}>
              {sharedViewsQuery.data.views.map((v) => (
                <Select.Option value={v.id} key={v.id}>
                  {v.name} - {v.username}
                </Select.Option>
              ))}
            </Select.OptGroup>
          </Select>
        </Col>
        <Col>
          <HeroButton
            color="primary"
            variant={newToggled ? "bordered" : "solid"}
            className={newToggled && "border-[#a12121] text-[#a12121] bg-white"}
            size="md"
            isDisabled={!canEdit}
            onClick={toggleNew}
          >
            {newToggled ? (
              <>{t("translation:cancel")}</>
            ) : (
              <>{t("translation:new_view")}</>
            )}
          </HeroButton>
        </Col>
        <Col>
          {viewQuery.data.view?.userId === user?.id && canEdit && (
            <HeroButton
              size="md"
              variant={editingView ? "solid" : "bordered"}
              color="primary"
              className={!editingView && "bg-white"}
              onClick={toggleIsEditing}
            >
              {!editingView ? (
                <>
                  <IconBuilder icon="Locked" color="#1475d7" size={18} />{" "}
                  {t("translation:edit_view")}
                </>
              ) : (
                <>
                  <IconBuilder icon="Unlocked" color="#ffffff" size={18} />{" "}
                  {t("translation:finish_editing")}
                </>
              )}
            </HeroButton>
          )}
        </Col>
        <Col>
          {viewQuery.data.view?.userId === user?.id && canEdit && (
            <HeroButton
              size="md"
              variant="bordered"
              className="border-[#a12121] text-[#a12121] bg-white"
              onClick={onOpen}
            >
              {t("translation:delete_view")}
            </HeroButton>
          )}
          <HeroModal
            disableAnimation={false}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="top"
          >
            {ModalBuilder({
              warning: true,
              modalHeader: t("translation:confirm_delete_view"),
              modalFooter: (
                <>
                  <HeroButton
                    size="sm"
                    color="default"
                    variant="bordered"
                    onClick={onOpenChange}
                    className="hover:border-primary-default hover:text-primary-default"
                  >
                    {t("translation:do_not_delete")}
                  </HeroButton>
                  <HeroButton
                    size="sm"
                    color="red"
                    onClick={deleteDashboardView}
                  >
                    {t("translation:delete")}
                  </HeroButton>
                </>
              ),
            })}
          </HeroModal>
        </Col>
        <Col>
          <Checkbox
            checked={!viewQuery.data.view?.isPrivate}
            onChange={handlePrivateToggle}
            disabled={viewQuery.data.view?.userId !== user?.id || !canEdit}
          >
            shared
          </Checkbox>
        </Col>
      </Row>
      {newToggled && (
        <NewView
          createView={createNewView}
          userViews={userViewsQuery.data.views}
          sharedViews={sharedViewsQuery.data.views}
        />
      )}
      {viewQuery.data.view?.id && (
        <Widgets view={viewQuery.data.view} editingView={editingView} />
      )}
    </div>
  );
}

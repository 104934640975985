import { iField, iLine } from "/app/src/models";

import { useCallback } from "react";
import { Row, Col } from "antd";
import { Form, Input, InputNumber } from "formik-antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { iFieldService, iLineService } from "/app/src/services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { orderBuilderLineFields } from "/app/src/constants/orderBuilderFields";
import { buildParams } from "/app/src/helpers";
import NewLineDetail from "./newLineDetail";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import HeroButton from "/app/src/components/HeroUi/Button";
import { handlePromiseError } from "/app/src/helpers/api";
import HeroDivider from "/app/src/components/HeroUi/Divider";

interface FormValues {
  quantity?: number;
  material?: string;
}

/**
 * Formats the form values to be sent to the backend API
 */
function formatForm(values: FormValues) {
  return { data: JSON.stringify(values) };
}

/**
 * Displays the details of a line in an order created through the Order Builder App
 */
export default function Line({
  line,
  disabled,
  integrationId,
  onlyLine,
}: {
  line: iLine;
  disabled: boolean;
  integrationId: number;
  onlyLine: boolean;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: lineFields } = useQuery({
    queryKey: ["lineFields", integrationId],
    queryFn: () => {
      return iFieldService.getAll(buildParams({ integrationId, type: "line" }));
    },
    initialData: { fields: [] },
    select: (data: { fields: iField[] }) => {
      return data.fields;
    },
  });
  const updateQueryData = useCallback(
    (lineId: number, data: iLine) => {
      queryClient.setQueryData(
        ["lines", { orderId: data.orderId }],
        (oldData: { lines: iLine[] }) => {
          return {
            lines: oldData.lines.map((l) => {
              if (l.id === lineId) {
                return data;
              }
              return l;
            }),
          };
        },
      );
    },
    [queryClient],
  );

  const handleUpdate = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await iLineService
        .updateSingle(line.id, formatForm(values))
        .then(handlePromiseError)
        .then((response) => {
          updateQueryData(line.id, response.line);
          actions.resetForm();
        });
    },
    [line.id, updateQueryData],
  );

  /**
   * Deletes a specific field from the line's loaded data and updates the line data on the server.
   *
   * @param fieldValue - The value of the field to be deleted.
   * @returns A function that performs the deletion and updates the server.
   *
   * The function performs the following steps:
   * 1. Removes the specified field from the `line.loadedData` using object destructuring.
   * 2. Converts the updated data to a JSON string.
   * 3. Sends the updated data to the server using `iLineService.updateSingle`.
   * 4. Updates the query cache with the new line data.
   *
   * @remarks
   * The `delete` operator is not used directly on the dynamic object for safety reasons.
   *
   * @example
   * ```typescript
   * const deleteField = deleteLineField("fieldName");
   * deleteField();
   * ```
   */
  const deleteLineField = useCallback(
    (fieldValue: string) => async () => {
      // Remove the field from the loadedData using Object.assign
      // because using delete operator on dynamic object is dangerous
      const { [fieldValue]: _, ...newLoadedData } = line.loadedData;
      const lineData = JSON.stringify(newLoadedData);
      return await iLineService
        .updateSingle(line.id, { data: lineData })
        .then(handlePromiseError)
        .then((response) => {
          updateQueryData(line.id, response.line);
        });
    },
    [line.id, line.loadedData, updateQueryData],
  );

  const handleDelete = useCallback(() => {
    iLineService.deleteSingle(line.id).then(() => {
      queryClient.setQueryData(
        ["lines", { orderId: line.orderId }],
        (oldData: { lines: iLine[] }) => {
          return {
            lines: oldData.lines.filter((l) => l.id !== line.id),
          };
        },
      );
    });
  }, [line.id, line.orderId, queryClient]);

  /**
   * Render the input fields. If the field is quantity, render an InputNumber
   * @param field
   */
  const renderInput = useCallback(
    (field, disabled) => {
      const commonProps = {
        name: field.value,
        label: field.label,
      };
      const lineField = lineFields.find((f) => f.name === field.value);
      return (
        <>
          <Col span={7} key={field.value}>
            <Form.Item {...commonProps}>
              {field.value === "quantity" ? (
                <InputNumber
                  disabled={lineField?.viewOnly || disabled}
                  name={field.value}
                  min={1}
                  size="large"
                />
              ) : (
                <Input
                  disabled={lineField?.viewOnly || disabled}
                  name={field.value}
                  suffix
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={1}>
            {field.value !== "quantity" && (
              <HeroButton
                onClick={deleteLineField(field.value)}
                color="default"
                size="md"
                variant="bordered"
                isIconOnly
                isDisabled={
                  disabled || lineField?.viewOnly || lineField?.required
                }
                className="border-error-default bg-white mt-[30px]"
              >
                <IconBuilder size={18} color="#e00000" icon="Delete" />
              </HeroButton>
            )}
          </Col>
        </>
      );
    },
    [deleteLineField, lineFields],
  );

  const lineForm: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ dirty, isValid }) => {
      const orderData = JSON.parse(line.data) || {};
      return (
        <Form layout="vertical">
          <FormikDisabler disabled={disabled} />
          <Row justify="start" gutter={16}>
            <Col span={4}>
              <h2>{t("translation:details")}</h2>
            </Col>
            <Col span={2} offset={16}>
              <DisabledSubmitButton
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:save")}
              </DisabledSubmitButton>
            </Col>
            <Col span={2}>
              <HeroButton
                onClick={handleDelete}
                color="default"
                size="md"
                variant="bordered"
                className="border-error-default bg-white text-error-default"
                isDisabled={disabled || onlyLine}
              >
                {"Delete"}
              </HeroButton>
            </Col>
          </Row>
          <HeroDivider className="my-3" />

          <Row justify="start" gutter={16}>
            {orderBuilderLineFields
              .filter((field) => field.value in orderData)
              .map((field) => renderInput(field, disabled))}
          </Row>
        </Form>
      );
    },
    [disabled, handleDelete, line.data, onlyLine, renderInput, t],
  );
  const getInitValues = useCallback(() => {
    return { ...line.loadedData };
  }, [line]);
  return (
    <Box>
      <Formik
        enableReinitialize
        component={lineForm}
        initialValues={getInitValues()}
        onSubmit={handleUpdate}
      />
      {line !== undefined && !disabled && (
        <NewLineDetail
          iLine={line}
          availableFields={orderBuilderLineFields.filter((field) => {
            const loadedDataKeys = line?.loadedData
              ? Object.keys(line.loadedData)
              : [];
            return !loadedDataKeys.includes(field.value);
          })}
        />
      )}
    </Box>
  );
}

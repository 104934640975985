import { Helmet } from "react-helmet";
import { appService } from "/app/src/services";
import { Row } from "antd";
import App, { SpreadsheetsApp } from "./app";
import Controls from "./_controls";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";

/**
 * Retrieves all apps and displays them in a list.
 */
export default function AppList() {
  const { t } = useTranslation();

  const { data: availableApps } = useQuery({
    queryKey: ["apps"],
    queryFn: () =>
      appService.getAll(buildParams({ status: "[nor]0;3", orderby: "name" })),
    initialData: { apps: [] },
  });

  const { data: unavailableApps } = useQuery({
    queryKey: ["unavailableApps"],
    queryFn: () =>
      appService.getAll(buildParams({ status: 0, orderby: "name" })),
    initialData: { apps: [] },
  });

  //useQuery to get count of unavailable apps
  const { data: unavailableAppsCount } = useQuery({
    queryKey: ["unavailableAppsCount"],
    queryFn: () => appService.getCount(buildParams({ status: 0 })),
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="apps">
      <Helmet>
        <title>{t("translation:apps")} - ItemPath</title>
      </Helmet>

      <Controls />
      <Row gutter={[24, 24]}>
        <>
          {availableApps.apps
            .filter((app) => !["CSV", "Custom Fields"].includes(app.name))
            .map((app) =>
              app.name === "XLSX" ? (
                <SpreadsheetsApp app={app} key={app.id} />
              ) : (
                <App app={app} key={app.id} availableApp />
              ),
            )}
        </>
      </Row>

      {unavailableAppsCount > 0 ? (
        <div>
          <div className="controls" style={{ paddingTop: 10 }}>
            <h1>{t("translation:unavailable_apps")}</h1>
          </div>
          <Row gutter={[24, 24]}>
            {unavailableApps.apps.map((app) => (
              <App app={app} key={app.id} availableApp={false} />
            ))}
          </Row>
        </div>
      ) : null}
    </div>
  );
}

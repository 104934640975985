import { Row, Col } from "antd";
import { Integration } from "/app/src/models";
import Timeout from "/app/src/components/generic/components/apps/data/settings/connectionSettings/timeout";
import Verify from "/app/src/components/generic/components/apps/data/settings/connectionSettings/verify";
import Path from "/app/src/components/generic/components/apps/data/settings/connectionSettings/path";
import { isAPIType } from "/app/src/helpers";

/**
 * ConnectionSettings component renders the settings for a given integration.
 *
 * @param {Object} props - The properties object.
 * @param {Integration} props.integration - The integration object.
 * @returns {JSX.Element} The rendered component.
 */
export default function ConnectionSettings({
  integration,
}: {
  integration: Integration;
}) {
  return (
    <Row justify="start" gutter={16}>
      {integration.connectionType === "REST" && (
        <Col span={10}>
          <Path integration={integration} />
        </Col>
      )}
      {isAPIType(integration.connectionType) && (
        <>
          <Col span={10}>
            <Timeout integration={integration} />
          </Col>
          <Col span={4}>
            <Verify integration={integration} />
          </Col>
        </>
      )}
    </Row>
  );
}

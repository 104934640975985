import { useState, useEffect } from "react";
import HeroProgressBar from "/app/src/components/HeroUi/ProgressBar";
import "./_status.scss";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { servicesService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { delay } from "/app/src/helpers/delay";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StatusType } from "/app/src/contexts/types";

/**
 *
 * @returns JSX.Element
 *
 */
export default function Restart() {
  const { message } = useStatusContext();
  const [percent, setPercent] = useState(0);
  const [connected, setConnected] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Interval for progress bar
   * @returns void
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (connected) {
        setPercent(100);
        delay(1000).then(() => {
          queryClient.setQueryData(["status"], (oldData: StatusType) => {
            return {
              ...oldData,
              message: "active",
            };
          });
        });
      }
      setPercent((prevPercent) => {
        if (prevPercent === 95 && !connected) {
          return prevPercent;
        }
        if (prevPercent < 100) {
          return prevPercent + 1;
        }
        return prevPercent;
      });
    }, 100);
    return () => clearInterval(interval);
  }, [connected, queryClient]);

  /**
   * Check if server is connected
   * @returns void
   */
  useEffect(() => {
    delay(1000).then(
      () => {
        servicesService.getStatus().then(() => {
          setConnected(true);
        });
      },
      () => {
        // empty because it handles unhandled promise
      },
    );
  }, []);

  /**
   * Checks if message is active
   */
  useEffect(() => {
    if (message === "active") {
      navigate("/status");
    }
  }, [message, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="items-center justify-center ">
        <HeroProgressBar
          value={percent}
          color={connected ? "success" : "primary"}
          size="md"
          className="w-[300px]"
        />
      </div>
      <div className="pt-xxlarge">
        <div className="text-neutral-charcoal font-semibold text-xlarge text-center">
          {t("translation:restart_itempath_title")}
        </div>
        <div className="text-[20px] flex flex-col text-center">
          {t("translation:restart_itempath_message")}
        </div>
      </div>
    </div>
  );
}

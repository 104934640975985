import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "antd";
import { Form, Select, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Snapshot as SnapshotType, ReportColumn } from "/app/src/models";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { snapshotService } from "/app/src/services";
import { useFormikContext } from "formik";
import HeroButton from "/app/src/components/HeroUi/Button";

interface FormValues {
  name: string | undefined;
  reportColumnId: number | undefined;
  groupColumnId: number | undefined;
  subGroupColumnId: number | undefined;
  type: string | undefined;
  sortDirection: string | undefined;
  groupRange: string;
}

/**
 * Component to display a form for a snapshot
 * This form is used for both creating and editing snapshots
 * The snapshot prop is optional, if it is not provided, the form will be used to create a new snapshot
 * @param reportColumns - list of report columns
 * @param snapshot - snapshot to edit (optional)
 */
export default function SnapshotForm({
  reportColumns,
  snapshot = { type: "count" },
}: {
  reportColumns: ReportColumn[];
  snapshot?: SnapshotType;
}) {
  const { t } = useTranslation();
  const [snapshotType, setSnapshotType] = useState(snapshot.type);
  const [selectedGroupColumn, setSelectedGroupColumn] =
    useState<ReportColumn>();

  const { canEdit } = useAccessContext();
  const queryClient = useQueryClient();
  const { dirty, isValid, setFieldValue } = useFormikContext<FormValues>();
  const { mutateAsync: removeSnapshot } = useMutation({
    mutationFn: (snapshotId: number) =>
      snapshotService.deleteSingle(snapshotId),
    onSuccess: () => {
      queryClient.setQueryData(
        ["snapshots", snapshot.reportId],
        (oldData: { snapshots: SnapshotType[] }) => ({
          snapshots: oldData.snapshots.filter((s) => s.id !== snapshot.id),
        }),
      );
    },
  });

  /**
   * Function to set the selected group column
   */
  const setColumn = useCallback(
    (columnId: number) => {
      const result = reportColumns.find((obj) => {
        return obj.id === columnId;
      });
      if (result) {
        setSelectedGroupColumn(result);
      } else {
        setSelectedGroupColumn(undefined);
      }
    },
    [reportColumns],
  );

  /**
   * Handler for when the group column field changes.
   * Sets the group column and resets the sub group column
   */
  const onGroupColumnChange = useCallback(
    (value: number) => {
      setColumn(value);
      setFieldValue("subGroupColumnId", null);
    },
    [setColumn, setFieldValue],
  );

  /**
   * Handler for when the snapshot type changes.
   * Resets the report column, group column, and sub group column
   */
  const onTypeChange = useCallback(
    (value) => {
      setSnapshotType(value);
      setFieldValue("reportColumnId", null);
      setFieldValue("groupColumnId", null);
      setFieldValue("subGroupColumnId", null);
    },
    [setFieldValue],
  );

  useEffect(() => {
    setColumn(snapshot.groupColumnId);
  }, [snapshot.groupColumnId, setColumn]);

  const mapColumns = useCallback((c: ReportColumn) => {
    return (
      <Select.Option key={c.id} value={c.id}>
        {c.name}
      </Select.Option>
    );
  }, []);

  /**
   * Handler for when the remove button is clicked.
   */
  const handleRemoveClick = useCallback(() => {
    removeSnapshot(snapshot.id);
  }, [removeSnapshot, snapshot.id]);

  return (
    <Form>
      <FormikDisabler disabled={!canEdit} />
      <Row justify="start" gutter={16}>
        <Col span={8}>
          <Form.Item name="name">
            <Input
              bordered={false}
              name="name"
              className={snapshot?.id ? "name" : "name new"} // skipcq: JS-0394
              placeholder={t("translation:enter_name")}
              size="large"
              suffix
            />
          </Form.Item>
        </Col>
        <Col span={3} offset={10}>
          {snapshot?.id && (
            <HeroButton
              size="md"
              variant="bordered"
              color="default"
              fullWidth
              isDisabled={!canEdit}
              onClick={handleRemoveClick}
              className="hover:border-primary-default hover:text-primary-default bg-white"
            >
              {t("translation:remove")}
            </HeroButton>
          )}
        </Col>
        <Col span={3}>
          <DisabledSubmitButton
            disabled={!dirty || !isValid}
            type="primary"
            size="large"
            block
          >
            {snapshot?.id ? t("translation:save") : t("translation:add")}
          </DisabledSubmitButton>
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col span={3}>
          <span className="blockText">{t("translation:generate")}</span>
        </Col>
        <Col span={2}>
          <Form.Item name="type">
            <Select
              name="type"
              size="large"
              placeholder={t("translation:select_method")}
              onChange={onTypeChange}
            >
              <Select.Option key="1" value="count">
                {t("translation:count")}
              </Select.Option>
              <Select.Option key="2" value="sum">
                {t("translation:sum")}
              </Select.Option>
              <Select.Option key="3" value="average">
                {t("translation:average")}
              </Select.Option>
              <Select.Option key="4" value="maximum">
                {t("translation:maximum")}
              </Select.Option>
              <Select.Option key="5" value="minimum">
                {t("translation:minimum")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}>
          <span className="blockText">{t("translation:of")}</span>
        </Col>
        <Col span={4}>
          {snapshotType === "count" ? (
            <Form.Item name="reportColumnId">
              <Input
                name="reportColumnId"
                disabled
                size="large"
                value={t("translation:report_rows")}
              />
            </Form.Item>
          ) : (
            <Form.Item name="reportColumnId">
              <SearchFilter
                name="reportColumnId"
                placeholder={t("translation:select_column")}
                mapOptionsFn={mapColumns}
                list={reportColumns}
                sort
                strictFilter
              />
            </Form.Item>
          )}
        </Col>
        <Col span={3}>
          <span className="blockText">{t("translation:grouped_by")}</span>
        </Col>
        <Col span={4}>
          <Form.Item name="groupColumnId">
            <SearchFilter
              allowClear
              name="groupColumnId"
              placeholder={t("translation:select_column")}
              mapOptionsFn={mapColumns}
              list={reportColumns}
              sort
              onChange={onGroupColumnChange}
              strictFilter
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          {selectedGroupColumn?.filterType === "DateTime" && (
            <IconToolTip
              content={t("translation:snapshot_date_time_warning")}
              icon="ExclamationCircle"
            />
          )}
        </Col>
        <Col span={3}>
          <Form.Item name="sortDirection">
            <Select
              name="sortDirection"
              size="large"
              placeholder={t("translation:sort_direction")}
            >
              <Select.Option key={0} value={null}>
                {t("translation:none")}
              </Select.Option>
              <Select.Option key={0} value={0}>
                {t("translation:ascending")}
              </Select.Option>
              <Select.Option key={1} value={1}>
                {t("translation:descending")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        {selectedGroupColumn?.filterType === "DateTime" && (
          <Col span={3}>
            <Form.Item name="groupRange">
              <Select
                name="groupRange"
                size="large"
                placeholder={t("translation:grouping_range")}
              >
                <Select.Option key="1" value="minute">
                  {t("translation:minute")}
                </Select.Option>
                <Select.Option key="2" value="hour">
                  {t("translation:hour")}
                </Select.Option>
                <Select.Option key="3" value="day">
                  {t("translation:day")}
                </Select.Option>
                <Select.Option key="4" value="week">
                  {t("translation:week")}
                </Select.Option>
                <Select.Option key="5" value="month">
                  {t("translation:month")}
                </Select.Option>
                <Select.Option key="6" value="year">
                  {t("translation:year")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row justify="start" gutter={16} className="">
        <Col span={2}>
          <span className="blockText">{t("translation:then")}</span>
        </Col>
        <Col span={4}>
          <Form.Item name="subGroupColumnId">
            <SearchFilter
              disabled={!selectedGroupColumn}
              allowClear
              name="subGroupColumnId"
              placeholder={t("translation:select_column")}
              mapOptionsFn={mapColumns}
              list={reportColumns.filter(
                (c) =>
                  c.id !== selectedGroupColumn?.id &&
                  c.filterType !== "DateTime",
              )}
              sort
              strictFilter
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

import type { UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import type { FlattenedItem, TreeItem, TreeItems } from "./types";

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

/**
 * Calculates the drag depth based on the given offset and indentation width.
 *
 * @param offset - The distance the item has been dragged.
 * @param indentationWidth - The width of one indentation level.
 * @returns The calculated drag depth as an integer.
 */
function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

/**
 * Calculates the maximum depth of a given item within a flattened tree structure.
 * The depth is determined by traversing up the tree from the given item until a
 * parent item of specific types ("grouping", "data", "lines", "confirmation-grouping")
 * is found, or the root is reached.
 *
 * @param previousItem - The item from which to start the depth calculation.
 * @param items - The array of all items in the flattened tree structure.
 * @returns The maximum depth of the given item.
 */
function getMaxDepth({
  previousItem,
  items,
}: {
  previousItem: FlattenedItem;
  items: FlattenedItem[];
}) {
  let maxDepth = 0;
  let currentItem = previousItem;

  while (currentItem) {
    if (
      ["grouping", "data", "lines", "confirmation-grouping", "array"].includes(
        currentItem.mapping.type,
      )
    ) {
      maxDepth = currentItem.depth + 1; // Set max depth to this grouping parent's depth + 1
      break;
    }
    const parentIndex = items.findIndex(
      (item) => item.id === currentItem.parentId,
    );
    currentItem = parentIndex !== -1 ? items[parentIndex] : null;
  }

  return maxDepth;
}

/**
 * Retrieves the minimum depth from the provided item.
 *
 * @param {Object} param - The parameter object.
 * @param {FlattenedItem} param.nextItem - The next item in the flattened structure.
 * @returns {number} The depth of the next item if it exists, otherwise 0.
 */
function getMinDepth({ nextItem }: { nextItem: FlattenedItem }) {
  if (nextItem) {
    return nextItem.depth;
  }
  return 0;
}

/**
 * Recursively flattens a tree structure into a list of items.
 *
 * @param items - The tree items to flatten.
 * @param parentId - The unique identifier of the parent item. Defaults to null.
 * @param depth - The current depth in the tree. Defaults to 0.
 * @returns An array of flattened items, each with additional properties: parentId, depth, and index.
 */
function flatten(
  items: TreeItems,
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): FlattenedItem[] {
  return items.reduce<FlattenedItem[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(item.children, item.id, depth + 1),
    ];
  }, []);
}

/**
 * Flattens a nested tree structure into a flat array of items.
 *
 * @param items - The tree structure to flatten.
 * @returns An array of flattened items.
 */
export function flattenTree(items: TreeItems): FlattenedItem[] {
  return flatten(items);
}

/**
 * Finds an item with the specified unique identifier within a list of tree items.
 * @param items list of TreeItems
 * @param itemId Unique identifier of the item to find
 * @returns The item with the specified unique identifier, or `undefined` if not found.
 */
export function findItem(items: TreeItem[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

/**
 * Builds a hierarchical tree structure from a list of flattened items.
 *
 * @param flattenedItems - An array of items where each item contains an id and optionally a parentId.
 * @returns A tree structure with nested children.
 *
 * @remarks
 * Each item in the flattenedItems array is expected to have a unique id. If an item does not have a parentId,
 * it will be considered a child of the root node. The function will create a root node with id "root" and
 * attach all top-level items to this root.
 *
 * @example
 * ```typescript
 * const flattenedItems = [
 *   { id: '1', parentId: null },
 *   { id: '2', parentId: '1' },
 *   { id: '3', parentId: '1' },
 *   { id: '4', parentId: '2' }
 * ];
 * const tree = buildTree(flattenedItems);
 * // Output:
 * // [
 * //   {
 * //     id: '1',
 * //     children: [
 * //       { id: '2', children: [{ id: '4', children: [] }] },
 * //       { id: '3', children: [] }
 * //     ]
 * //   }
 * // ]
 * ```
 */
export function buildTree(flattenedItems: FlattenedItem[]): TreeItems {
  const root: TreeItem = { id: "root", children: [] };
  const nodes: Record<string, TreeItem> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);

    nodes[id] = { id, children };
    parent.children.push(item);
  }

  return root.children;
}

/**
 * Recursively searches for an item with the specified unique identifier within a tree structure.
 *
 * @param items - An array of tree items to search within.
 * @param itemId - The unique identifier of the item to find.
 * @returns The tree item with the specified unique identifier, or `undefined` if not found.
 */
export function findItemDeep(
  items: TreeItems,
  itemId: UniqueIdentifier,
): TreeItem | undefined {
  for (const item of items) {
    const { id, children } = item;

    if (id === itemId) {
      return item;
    }

    if (children.length) {
      const child = findItemDeep(children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return null;
}

/**
 * Removes an item with the specified unique identifier from a tree structure.
 *
 * @param items - The array of tree items to search through.
 * @param id - The unique identifier of the item to remove.
 * @returns A new array of tree items with the specified item removed.
 */
export function removeItem(items: TreeItems, id: UniqueIdentifier) {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id);
    }

    newItems.push(item);
  }

  return newItems;
}

/**
 * Updates a specified property of a `TreeItem` within a list of `TreeItems`.
 *
 * @template T - The type of the property to be updated.
 * @param {TreeItems} items - The list of tree items to search through.
 * @param {UniqueIdentifier} id - The unique identifier of the tree item to update.
 * @param {T} property - The property of the tree item to update.
 * @param {(value: TreeItem[T]) => TreeItem[T]} setter - A function that takes the current value of the property and returns the new value.
 * @returns {TreeItems} A new list of tree items with the updated property.
 */
export function setProperty<T extends keyof TreeItem>(
  items: TreeItems,
  id: UniqueIdentifier,
  property: T,
  setter: (value: TreeItem[T]) => TreeItem[T],
) {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
      continue;
    }

    if (item.children.length) {
      item.children = setProperty(item.children, id, property, setter);
    }
  }

  return [...items];
}

/**
 * Recursively counts the total number of child nodes in a tree structure.
 *
 * @param items - An array of tree items to count children from.
 * @param count - The initial count of children, defaults to 0.
 * @returns The total number of child nodes.
 */
function countChildren(items: TreeItem[], count = 0): number {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + 1;
  }, count);
}

/**
 * Retrieves the count of children for a given item in a tree structure.
 *
 * @param items - The tree items to search within.
 * @param id - The unique identifier of the item whose children count is to be retrieved.
 * @returns The number of children for the specified item. Returns 0 if the item is not found.
 */
export function getChildCount(items: TreeItems, id: UniqueIdentifier) {
  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

/**
 * Removes items from a list that are children of specified parent IDs.
 *
 * @param items - The list of items to filter.
 * @param ids - The list of parent IDs whose children should be removed.
 * @returns A new list of items excluding the children of the specified parent IDs.
 */
export function removeChildrenOf(
  items: FlattenedItem[],
  ids: UniqueIdentifier[],
) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}

/**
 * Calculates the projected depth and parent ID for an item being dragged within a hierarchical list.
 *
 * @param {FlattenedItem[]} items - The list of items in a flattened structure.
 * @param {UniqueIdentifier} activeId - The ID of the item currently being dragged.
 * @param {UniqueIdentifier} overId - The ID of the item over which the dragged item is currently positioned.
 * @param {number} dragOffset - The offset distance the item has been dragged.
 * @param {number} indentationWidth - The width of the indentation for each hierarchical level.
 * @returns {Object} An object containing the projected depth, maximum depth, minimum depth, and parent ID.
 * @returns {number} return.depth - The projected depth of the dragged item.
 * @returns {number} return.maxDepth - The maximum allowable depth for the dragged item.
 * @returns {number} return.minDepth - The minimum allowable depth for the dragged item.
 * @returns {(string | null)} return.parentId - The ID of the parent item if a valid grouping parent is found, otherwise `null`.
 */
export function getProjection(
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({ previousItem, items: newItems });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  /**
   * Retrieves the parent ID based on the current depth and previous item in the hierarchy.
   *
   * @returns {(string | null)} The parent ID if a valid grouping parent is found, otherwise `null`.
   *
   * The function works as follows:
   * - If the depth is 0 or there is no previous item, it returns `null`.
   * - It traverses up the hierarchy to find a grouping parent.
   * - If a grouping parent is found at the same depth, it returns the parent's ID.
   * - If a grouping parent is found at a deeper depth, it returns the current item's ID.
   * - If no valid grouping parent is found, it returns `null`.
   */
  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    // Traverse up the hierarchy until a grouping parent is found
    let currentItem = previousItem;
    const findParentIndex = (parentId: UniqueIdentifier) =>
      newItems.findIndex((item) => item.id === parentId);
    while (currentItem) {
      if (
        [
          "grouping",
          "data",
          "lines",
          "confirmation-grouping",
          "array",
        ].includes(currentItem.mapping.type)
      ) {
        // If at the same depth, return parent's ID
        if (depth === currentItem.depth) {
          return currentItem.parentId;
        }
        // If deeper, return the current item ID
        if (depth > currentItem.depth) {
          return currentItem.id;
        }
      }
      // Move up to the parent
      const parentIndex = findParentIndex(currentItem.parentId);
      currentItem = parentIndex !== -1 ? newItems[parentIndex] : null;
    }

    return null; // No valid grouping parent found
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };
}

import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { HeroUIProvider } from "@heroui/react";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HeroUIProvider>
      <main className="itempath text-foreground bg-background min-h-screen">
        <App />
      </main>
    </HeroUIProvider>
  </React.StrictMode>,
);

import { object, string } from "yup";
import { orderService } from "/app/src/services";
import i18next from "/app/src/locales/config";
import { buildParams } from "/app/src/helpers/params";

export const newOrderSchema = object().shape({
  name: string()
    .required(i18next.t("translation:order_name_is_required"))
    .test(
      "order-unique-validation", // Name
      i18next.t("translation:order_already_exists"), // Msg
      (value) => {
        return orderService
          .getAll(buildParams({ name: value }))
          .then((response) => {
            return response.orders.length === 0;
          });
      },
    ),
  warehouseName: string().required(i18next.t("translation:required")),
});

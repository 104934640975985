import HeroSpin from "/app/src/components/HeroUi/Spin";
import { Chip } from "@heroui/react";
import SnapshotChart from "./snapshotChart";
import ReportTable from "./reportTable";
import { Report, ReportColumn } from "/app/src/models";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

/**
 * Component to display the snapshot chart and report table
 * @param param0 props contain report and report columns
 */
export default function Data({
  report,
  columns,
}: {
  report: Report;
  columns: ReportColumn[];
}) {
  const [dateValues, setDateValues] = useState({
    cache_expiration: null,
    powerpick_timestamp: null,
    variance_timestamp: null,
  });
  const { t } = useTranslation();

  const handleDateValues = useCallback((values) => {
    setDateValues(values);
  }, []);
  return (
    <>
      {report?.isVariance ? (
        <div className="my-2 flex justify-between">
          <Chip
            startContent={
              <IconBuilder icon="Calendar" size={16} color="#ffffff" />
            }
            className="p-2"
            variant="solid"
            size="sm"
            color="primary"
          >
            {t("translation:data_cached")}
            <DateTime
              includeSeconds
              date={
                dateValues.variance_timestamp > dateValues.powerpick_timestamp
                  ? dateValues.variance_timestamp
                  : dateValues.powerpick_timestamp
              }
            />
          </Chip>
          <Chip
            startContent={
              <IconBuilder icon="Refresh" size={16} color="#ffffff" />
            }
            className="p-2 mr-2 bg-secondary-default"
            variant="solid"
            size="sm"
            color="primary"
          >
            {t("translation:next_refresh")}
            <DateTime includeSeconds date={dateValues.cache_expiration} />
          </Chip>
        </div>
      ) : null}
      <div className="reportData">
        {report.id ? (
          <>
            <SnapshotChart report={report} />
            <ReportTable
              columns={columns}
              report={report}
              dateValues={handleDateValues}
            />
          </>
        ) : (
          <div className="dataTable flex justify-center p-10 h-[400px]">
            <HeroSpin size="lg" />
          </div>
        )}
      </div>
    </>
  );
}

import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, InputNumber } from "formik-antd";
import { costCenterService, materialService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Material, CostCenter } from "/app/src/models";
import { newOrderLineSchema } from "/app/src/schemas/apps/orderBuilder/newOrderLineSchema";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { useQuery } from "@tanstack/react-query";
import { OrderBuilderLine } from "./orderLine/type";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";

export default function NewOrderLine({
  addLine,
}: {
  addLine: (line: OrderBuilderLine) => void;
}) {
  const { t } = useTranslation();

  const { data: materials } = useQuery({
    queryKey: ["materials"],
    queryFn: () => {
      return materialService.getAll();
    },
    initialData: { materials: [] },
    select: (data: { materials: Material[] }) => data.materials,
  });

  const { data: costCenters } = useQuery({
    queryKey: ["costCenters"],
    queryFn: () => {
      return costCenterService.getAll();
    },
    initialData: { cost_centers: [] },
    select: (data: { cost_centers: CostCenter[] }) => data.cost_centers,
  });

  const onSubmitHandler = useCallback(
    (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
      addLine({
        materialId: values.materialId,
        quantity: values.quantity,
        costCenterName: values.costCenterName,
      });
      actions.resetForm();
    },
    [addLine],
  );

  /**
   * Map materials to select options
   * @param c material
   */
  const mapMaterials = useCallback((c: Material) => {
    const displayText =
      c.Info1 !== undefined && c.Info1 !== null
        ? `${c.name} - ${c.Info1}`
        : c.name;
    return (
      <Select.Option key={c.id} value={c.id}>
        {displayText} - {c.currentQuantity}
      </Select.Option>
    );
  }, []);

  const materialLabelContent = useMemo(() => {
    return (
      <>
        {t("translation:material")}
        <IconToolTip
          className="ml-1"
          content={`${t("translation:material")} - ${t("translation:info1")} - ${t("translation:current_quantity")} `}
        />
      </>
    );
  }, [t]);

  const newOrderLineForm: (props: FormikProps<FormikValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={10}>
              <Form.Item label={materialLabelContent} name="materialId">
                <SearchFilter
                  name="materialId"
                  placeholder={`${t("translation:material")} - ${t("translation:info1")} - ${t("translation:current_quantity")} `}
                  mapOptionsFn={mapMaterials}
                  list={materials}
                  sort={false}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={t("translation:quantity")}
                name="quantity"
                hasFeedback={false}
              >
                <InputNumber
                  className="!w-full"
                  name="quantity"
                  placeholder=""
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={t("translation:cost_center")}
                name="costCenterName"
              >
                <Select
                  name="costCenterName"
                  placeholder={t("translation:cost_center")}
                  size="large"
                >
                  {costCenters?.map((c) => (
                    <Select.Option key={c.id} value={c.name}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
                className="mt-[30px]"
              >
                {t("translation:add")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [materialLabelContent, t, mapMaterials, materials, costCenters],
    );

  return (
    <div className="newOrderLine">
      <Formik
        component={newOrderLineForm}
        enableReinitialize
        initialValues={{
          quantity: 1,
          materialId: "",
        }}
        validationSchema={newOrderLineSchema}
        onSubmit={onSubmitHandler}
      />
    </div>
  );
}

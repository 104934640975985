import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en_translation from "./en/translation.json";
import fr_translation from "./fr/translation.json";
import Backend from "i18next-http-backend";

export const defaultNS = "translation";
export const resources = {
  en: {
    translation: en_translation,
  },
  fr: {
    translation: fr_translation,
  },
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development",
    defaultNS,
    resources,
    lng: "en",
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
  });

export default i18next;

import { Col } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { integrationService } from "/app/src/services";
import { WorkflowAction, Report, Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import HeroButton from "/app/src/components/HeroUi/Button";
import { useCallback } from "react";

export default function CSV({
  reports,
  isNew,
  removeWorkflowAction,
  dirty,
  appId,
  action,
}: {
  reports: Report[];
  isNew: boolean;
  removeWorkflowAction?: (action: WorkflowAction) => void;
  dirty: boolean;
  action?: WorkflowAction;
  appId: number | undefined;
}) {
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    if (removeWorkflowAction && action) {
      removeWorkflowAction(action);
    }
  }, [removeWorkflowAction, action]);

  const { data: integrations } = useQuery({
    queryKey: ["integrationsByApp", appId],
    queryFn: () => {
      return integrationService.getAll(buildParams({ appId }));
    },
    enabled: Boolean(appId),
    initialData: { integrations: [] },
    select: (data: { integrations: Integration[] }) => {
      return data.integrations;
    },
  });

  return (
    <>
      <Col span={7}>
        <Form.Item name="reportId">
          <Select
            name="reportId"
            size="large"
            placeholder={t("translation:select_report")}
          >
            {reports.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item name="integrationId">
          <Select
            name="integrationId"
            size="large"
            placeholder={t("translation:select_delimiter")}
          >
            {integrations.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={5}>
        <SubmitButton type="primary" size="large" block disabled={!dirty}>
          {isNew ? t("translation:add_action") : t("translation:save")}
        </SubmitButton>
      </Col>
      {!isNew && (
        <Col span={3}>
          <HeroButton
            size="md"
            variant="bordered"
            color="default"
            fullWidth
            onClick={handleClick}
            className="hover:border-primary-default hover:text-primary-default bg-white"
          >
            {t("translation:remove")}
          </HeroButton>
        </Col>
      )}
    </>
  );
}

import React, { useState, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Row, Col, Select } from "antd";
import { useDebounce } from "/app/src/hooks";
import Search from "../search";
import { locationService, zoneService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Zone as ZoneType, Location as LocationType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/app/src/components/generic/tables/table";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import Box from "/app/src/components/generic/components/box";
import usePaginatedData from "/app/src/hooks/usePaginatedData";
const { Option } = Select;

export default function Locations() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fillFilter, setFillFilter] = useState("all");
  const [zoneFilter, setZoneFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const debouncedSearchTerm = useDebounce(searchString, 1000);

  const goToLocation = useCallback(
    (id: number) => {
      navigate(`/explorer/locations/${id}`);
    },
    [navigate],
  );

  const settingFillFilter = useCallback(
    (value) => {
      setFillFilter(value);
    },
    [setFillFilter],
  );

  const settingZoneFilter = useCallback(
    (value) => {
      setZoneFilter(value);
    },
    [setZoneFilter],
  );

  const columnHelper = createColumnHelper<LocationType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("typeDescription", {
        id: "typeDescription",
        cell: (info) => info.getValue(),
        header: t("translation:type"),
      }),
      columnHelper.accessor("isMarkedFull", {
        id: "isMarkedFull",
        cell: (info) => {
          if (info.getValue() === 0) {
            return t("translation:no");
          }
          return t("translation:yes");
        },
        header: t("translation:marked_full"),
      }),
    ],
    [t, columnHelper],
  );

  const { data: zones } = useQuery({
    queryKey: ["zones"],
    queryFn: () => {
      return zoneService.getAll();
    },
    initialData: { zones: [] },
    select: (data: { zones: ZoneType[] }) => {
      return data.zones;
    },
  });

  const {
    data: locations,
    count: locationsCount,
    isFetching,
  } = usePaginatedData<LocationType>({
    queryKey: ["locations"],
    searchString: debouncedSearchTerm,
    page,
    pageSize,
    sort,
    service: locationService,
    options: {
      isMarkedFull: fillFilter,
      zoneId: zoneFilter,
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:locations")} - ItemPath</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={12}>
            <Search setSearchString={setSearchString} />
          </Col>
          <Col span={6}>
            <Select
              onChange={settingZoneFilter}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_zone")}
            >
              <Option value="all">{t("translation:all")}</Option>
              {zones.map((zone) => (
                <Option value={zone.id} key={zone.id}>
                  {zone.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              onChange={settingFillFilter}
              size="large"
              style={{ width: "100%" }}
              placeholder={t("translation:filter_by_marked_full")}
            >
              <Option value="all">{t("translation:all")}</Option>
              <Option value={1} key={1}>
                {t("translation:full")}
              </Option>
              <Option value={0} key={0}>
                {t("translation:not_full")}
              </Option>
            </Select>
          </Col>
        </Row>
      </Box>
      <Table
        loading={isFetching}
        rows={locations}
        tableColumns={columns}
        length={locationsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        enableRowSelection
        rowClicked={goToLocation}
        emptyText={t("translation:no_locations_found")}
      />
    </div>
  );
}

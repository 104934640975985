import { useTranslation } from "react-i18next";
import HeroModal from "/app/src/components/HeroUi/Modal";
import HeroButton from "/app/src/components/HeroUi/Button";
import { useDisclosure } from "@heroui/react";
import ModalBuilder from "/app/src/components/HeroUi/helpers/ModalBuilder";
import { useCallback } from "react";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

export default function DeleteButtonPopup({
  deleteObjectFn,
  refresh,
}: {
  deleteObjectFn: () => Promise<any>;
  refresh: () => void;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const deleteObject = useCallback(() => {
    deleteObjectFn().then((response) => {
      // Refresh the list of reports if channel successfully removed
      if (response.deleted === "true") {
        refresh();
      }
    });
  }, [deleteObjectFn, refresh]);

  return (
    <>
      <button
        onClick={onOpen}
        onKeyDown={onOpen}
        className="bg-transparent border-0"
      >
        <IconBuilder icon="Delete" color="#B5B5B5" />
      </button>
      <HeroModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:confirm_delete_integration"),
          modalFooter: (
            <>
              <HeroButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:do_not_delete")}
              </HeroButton>
              <HeroButton size="sm" color="red" onClick={deleteObject}>
                {t("translation:delete")}
              </HeroButton>
            </>
          ),
        })}
      </HeroModal>
    </>
  );
}

import { iOrder as iOrderType } from "/app/src/models";
import { iOrderService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Row, Col } from "antd";
import { Form, Select } from "formik-antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";

interface FormValues {
  field?: string;
}

/**
 * NewDetail component renders a form to add new details to an order.
 *
 * @param {Object} props - The properties object.
 * @param {iOrderType} props.iOrder - The order object containing loaded data.
 * @param {Array<{ label: string; value: string }>} props.availableFields - The available fields for selection in the form.
 *
 * @returns {JSX.Element} The rendered NewDetail component.
 *
 * @example
 * <NewDetail iOrder={order} availableFields={fields} />
 *
 * @component
 */
export default function NewDetail({
  iOrder,
  availableFields,
}: {
  iOrder: iOrderType;
  availableFields: { label: string; value: string }[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const saveOrder = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      const locationNames = ["carriers", "locations", "storageUnits", "zones"];
      if (locationNames.includes(values.field)) {
        iOrder.loadedData[values.field] = [];
      } else {
        iOrder.loadedData[values.field] = "";
      }
      const orderData = JSON.stringify(iOrder.loadedData);
      return await iOrderService
        .updateSingle(iOrder.id, { data: orderData })
        .then((response) => {
          queryClient.setQueryData(["order", iOrder.id], response);
          actions.resetForm();
        });
    },
    [iOrder.id, iOrder.loadedData, queryClient],
  );

  const newOrderDetailForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={20}>
              <Form.Item name="field" label={t("translation:field")}>
                <Select
                  name="field"
                  size="large"
                  placeholder={t("translation:add_new_order_detail")}
                >
                  {availableFields.map((field) => (
                    <Select.Option key={field.value} value={field.value}>
                      {field.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <DisabledSubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!(dirty && isValid)}
              >
                {t("translation:add")}
              </DisabledSubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [availableFields, t],
    );

  return (
    <Formik
      enableReinitialize
      component={newOrderDetailForm}
      initialValues={{ field: undefined }}
      onSubmit={saveOrder}
    />
  );
}
